import React from "react";
import {
  Box,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  AlertTitle,
  Link,
  Chip,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DescriptionIcon from "@mui/icons-material/Description";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import ArticleIcon from "@mui/icons-material/Article";

import { marked } from "marked";
import { chatDownloadFileUrl } from "../../api/general";

// Helper function to get the appropriate icon based on file extension
const getFileIcon = (fileName) => {
  const extension = fileName.split(".").pop().toLowerCase();
  switch (extension) {
    case "pdf":
      return <PictureAsPdfIcon />;
    case "doc":
    case "docx":
      return <ArticleIcon />;
    case "txt":
      return <TextSnippetIcon />;
    // Add more cases as needed
    default:
      return <DescriptionIcon />;
  }
};

const ToolMessage = ({
  msg,
  theme,
  chatbotLoading,
  sendMessage,
  submittedSuccessfully,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        // my: 1,
      }}
    >
      {msg.tool_name === "get_relevant_vendors" &&
        JSON.parse(msg.content.replace(/'/g, '"')).map((vendor, idx) => (
          <Button
            key={idx}
            onClick={() => {
              if (!chatbotLoading) {
                const formattedVendor = vendor
                  .replace(/_/g, " ")
                  .replace(/\b\w/g, (c) => c.toUpperCase());
                sendMessage(`I prefer ${formattedVendor}`, []);
              }
            }}
            disabled={chatbotLoading || submittedSuccessfully}
            sx={{ p: 1, m: 1 }}
          >
            <img
              src={`/vendors/${vendor}.svg`}
              alt={vendor}
              onError={(e) => {
                console.log("Error loading vendor image: ", vendor);
                e.target.onerror = null;
                e.target.src = "/vendors/Default.svg";
              }}
              style={{ width: 96, height: 96 }}
            />
          </Button>
        ))}
      {msg.tool_name === "search_company_policy" && (
        <Accordion
          sx={{
            my: 0,
            pt: 1,
            border: `4px solid ${theme.palette.success.main}`,

            backgroundColor: theme.palette.background.paper,
          }}
          elevation={0}
          disableGutters
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <AlertTitle sx={{ color: theme.palette.success.main }}>
              Company Intake Policy
            </AlertTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              dangerouslySetInnerHTML={{
                __html: marked(`${msg.content}`),
              }}
            />
          </AccordionDetails>
        </Accordion>
      )}
      {msg.tool_name === "get_requests_list" && (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <List>
            {JSON.parse(msg.content.replace(/'/g, '"')).map((request, idx) => (
              <ListItem key={idx} disablePadding>
                <ListItemButton
                  onClick={() =>
                    sendMessage(
                      `I want to know the status of request ${request.id}`,
                      []
                    )
                  }
                  disabled={chatbotLoading || submittedSuccessfully}
                  sx={{
                    p: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AssignmentIcon sx={{ mx: 1 }} />{" "}
                  <ListItemText
                    primary={
                      <Typography
                        variant="body2"
                        fontWeight="bold"
                        sx={{ mr: 1 }}
                      >
                        {request.id}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="body2" sx={{ ml: 1 }}>
                        {request.title}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
      {/* Handler for Download Document Tool */}
      {msg.tool_name === "get_document_docx" && (
        <Box sx={{ mt: 2, width: "100%" }}>
          <Chip
            icon={getFileIcon(msg.artifact.document_name)}
            label={msg.artifact.document_name}
            component="a"
            href={`${chatDownloadFileUrl}/${msg.artifact.file_id}/${msg.artifact.document_name}`}
            target="_blank"
            clickable
            color="warning"
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "40%",
              p: 1,
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default ToolMessage;
