import { useState, useEffect, useRef } from "react";
import { fetchRequestDetails } from "../api/request";
import { checkForNewMails } from "../api/email"; // Import the function
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import SummarizeIcon from "@mui/icons-material/Summarize";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";

const useRequest = (requestId) => {
  const [messages, setMessages] = useState([
    // Initial messages can be uncommented or modified as needed
    // {
    //   role: "assistant",
    //   content: "Hello! How can I assist you today?",
    // },
    // {
    //   role: "user",
    //   content:
    //     "I need help with my request. Can you show me the list of all vendors?",
    // },
    // {
    //   role: "assistant",
    //   content: "Here is the list of all vendors: Vendor 1, Vendor 2, Vendor 3",
    // },
  ]);
  const [request, setRequest] = useState(null);
  const [drawerLoadingCount, setDrawerLoadingCount] = useState(0);
  const [conversationOver, setConversationOver] = useState(false);
  const [allFiles, setAllFiles] = useState([]);
  const [activeAgent, setActiveAgent] = useState("request_manager");
  const [activeTab, setActiveTab] = useState(0);
  const [relatedConversations, setRelatedConversations] = useState({});
  const [sortedRelatedConversations, setSortedRelatedConversations] = useState(
    []
  ); // New state
  const [requestSteps, setRequestSteps] = useState({});
  const [requestForms, setRequestForms] = useState([]);
  const [replySubject, setReplySubject] = useState("");
  const [replyBody, setReplyBody] = useState("");
  const [toTags, setToTags] = useState([]);
  const [ccTags, setCcTags] = useState([]);
  const [bccTags, setBccTags] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [currentView, setCurrentView] = useState("list"); // 'list', 'conversation', 'compose'
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [draftingEmailId, setDraftingEmailId] = useState(null); // New state

  // const latestEmailDateRef = useRef("1970-01-01 00:00:00"); // Initialize to a very old date

  // // Helper function to compute the latest email date
  // const computeLatestEmailDate = (conversations) => {
  //   let latestDate = latestEmailDateRef.current;

  //   conversations.forEach((conv) => {
  //     conv.emails.forEach((email) => {
  //       if (email.received_date > latestDate) {
  //         latestDate = email.received_date;
  //       }
  //     });
  //   });

  //   latestEmailDateRef.current = latestDate;
  // };

  // const handleNewMessages = async (newEmails) => {
  //   console.log("NEW MESSAGES: ", newEmails);

  //   setRelatedConversations((prevConversations) => {
  //     const updatedConversations = [...prevConversations];

  //     newEmails.forEach((email) => {
  //       const conversationIndex = updatedConversations.findIndex(
  //         (conv) => conv.conversation_id === email.conversation_id
  //       );

  //       if (conversationIndex !== -1) {
  //         updatedConversations[conversationIndex].emails.push(email);
  //       } else {
  //         updatedConversations.push({
  //           conversation_id: email.conversation_id,
  //           emails: [email],
  //         });
  //       }
  //     });

  //     // Compute and update the latestEmailDate after adding new emails
  //     computeLatestEmailDate(updatedConversations);

  //     return updatedConversations;
  //   });
  // };
  const initialOptions = [
    {
      icon: <MarkEmailReadIcon sx={{ color: "error.main" }} />,
      description: "Draft email for the next step",
      message: "Draft an email for the next step.",
    },
    {
      icon: <SummarizeIcon sx={{ color: "success.main" }} />,

      description: "Latest project status",
      message: "Show me the latest status of the project.",
    },
    {
      icon: <RequestQuoteIcon sx={{ color: "warning.main" }} />,
      description: "Create an RFQ document",
      message: "Draft an RFQ for the request based on the intake form details.",
    },
  ];

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setSelectedConversation(null);
  };

  const getRequestDetails = async () => {
    try {
      const { request, related_conversations } = await fetchRequestDetails(
        requestId
      );

      console.log("request", request);

      setRequest(request); // **Add this line**
      setRequestSteps(request.steps);
      setRequestForms(request.forms);
      setRelatedConversations(related_conversations);

      // Compute the latestEmailDate after fetching conversations
      // computeLatestEmailDate(related_conversations);
    } catch (error) {
      console.error("Failed to fetch request details:", error);
      // You might want to set an error state here
    }
  };

  useEffect(() => {
    if (requestId) {
      getRequestDetails();
    }
  }, [requestId]);

  useEffect(() => {
    const sortConversations = () => {
      const sorted = Object.entries(relatedConversations)
        .map(([conversationId, emails]) => ({
          conversationId,
          emails,
          latestEmailDate: emails.reduce((latest, email) => {
            return email.received_date > latest ? email.received_date : latest;
          }, "1970-01-01T00:00:00"),
        }))
        .sort(
          (a, b) => new Date(b.latestEmailDate) - new Date(a.latestEmailDate)
        );

      setSortedRelatedConversations(sorted);
    };

    sortConversations();
  }, [relatedConversations]);

  // useEffect(() => {
  //   const pollForNewMails = async () => {
  //     console.log("Checking for new mails");
  //     try {
  //       const newEmails = await checkForNewMails(
  //         latestEmailDateRef.current,
  //         requestId
  //       );
  //       if (newEmails && newEmails.length > 0) {
  //         handleNewMessages(newEmails);
  //       }
  //     } catch (error) {
  //       console.error("Error checking for new mails:", error);
  //     }
  //   };

  //   // Initial call
  //   pollForNewMails();

  //   // Set up polling every 10 seconds
  //   const intervalId = setInterval(pollForNewMails, 10000);

  //   return () => clearInterval(intervalId);
  // }, [requestId]);

  const handleNewMessages = ({ newMessages, updateId }) => {
    console.log("newMessages", newMessages, updateId);

    for (const message of newMessages) {
      if (message.tool_name === "update_request_steps") {
        setRequestSteps(message.artifact);
      }
      if (message.tool_name === "draft_email") {
        const {
          to_recipients,
          cc_recipients,
          bcc_recipients,
          subject,
          body_html,
          reply_to_email_id,
        } = message.artifact;

        setToTags(to_recipients.map((email) => ({ id: email, text: email })));
        setCcTags(
          cc_recipients
            ? cc_recipients.map((email) => ({ id: email, text: email }))
            : []
        );
        setBccTags(
          bcc_recipients
            ? bcc_recipients.map((email) => ({ id: email, text: email }))
            : []
        );
        setReplySubject(subject);
        setReplyBody(body_html);

        setActiveTab(1);
        if (reply_to_email_id) {
          // Find the conversation containing the email with reply_to_email_id
          const conversation = sortedRelatedConversations.find((conv) =>
            conv.emails.some((email) => email.id === reply_to_email_id)
          );

          if (conversation) {
            setSelectedConversation(conversation);
            console.log(
              "setting draftingEmailId to reply_to_email_id",
              reply_to_email_id
            );
            setDraftingEmailId(reply_to_email_id); // Set the drafting email ID
            setCurrentView("conversation");
          }
        } else {
          // Set the state to indicate it's a new email
          setSelectedConversation(null);
          console.log("setting draftingEmailId to null");
          setDraftingEmailId(null); // No specific email to draft
          setCurrentView("compose");
        }
      }
    }
  };

  useEffect(() => {
    console.log("LOGGING draftingEmailId", draftingEmailId);
  }, [draftingEmailId]);

  // Function to reset drafting state
  const resetDrafting = () => {
    setReplySubject("");
    setReplyBody("");
    setToTags([]);
    setCcTags([]);
    setBccTags([]);
    setAttachments([]);
    console.log("resetting draftingEmailId to null resetDrafting");
    setDraftingEmailId(null);

    console.log("resetting drafting");
    console.log("draftingEmailId", draftingEmailId);
  };

  return {
    request,
    messages,
    setMessages,
    drawerLoadingCount,
    setDrawerLoadingCount,
    conversationOver,
    setConversationOver,
    allFiles,
    setAllFiles,
    activeAgent,
    setActiveAgent,
    activeTab,
    setActiveTab,
    relatedConversations,
    sortedRelatedConversations, // Return the new state
    handleTabChange,
    handleNewMessages,
    requestSteps,
    requestForms,
    replySubject,
    setReplySubject,
    replyBody,
    setReplyBody,
    toTags,
    setToTags,
    ccTags,
    setCcTags,
    bccTags,
    setBccTags,
    attachments,
    setAttachments,
    selectedConversation,
    setSelectedConversation,
    currentView,
    setCurrentView,
    draftingEmailId, // Expose the drafting email ID
    setDraftingEmailId, // Optionally expose the setter
    resetDrafting, // Expose the reset function
    initialOptions,
    getRequestDetails,
  };
};

export default useRequest;
