import React, { useState, useRef } from "react";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

const EmailTagInput = ({ tags, setTags, label, contacts }) => {
  const theme = useTheme();
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);
  const autocompleteRef = useRef(null); // Add a ref for the autocomplete

  const handleDelete = (index) => {
    setTags(tags.filter((_, i) => i !== index));
  };

  const handleAddition = (tag) => {
    const suggestion = contacts.atn ? contacts.atn[tag] || tag : tag;
    setTags((prevTags) => {
      const updatedTags = [...prevTags, { id: tag, text: suggestion }];

      return updatedTags;
    });
  };

  const handleBlur = () => {
    if (inputValue.trim()) {
      const trimmedValue = inputValue.trim();
      handleAddition(trimmedValue);
      setInputValue("");
    }
  };

  const emailSuggestions = contacts.atn
    ? Object.entries(contacts.atn).map(([id, text]) => ({
        id,
        text,
      }))
    : [];

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        pl: 2,
        my: 1,
        backgroundColor: theme.palette.background.default,
      }}
      ref={inputRef}
    >
      <Typography
        variant="body2"
        sx={{
          width: "2rem",
          mr: 1,
          color: theme.palette.text.disabled,
          backgroundColor: theme.palette.background.default,
        }}
      >
        {label}:
      </Typography>

      <Autocomplete
        fullWidth
        multiple
        freeSolo
        autoHighlight
        options={emailSuggestions.map(
          (option) => `${option.text} <${option.id}>`
        )}
        value={tags.map((tag) => `${tag.text} <${tag.id}>`)}
        onChange={(event, newValue) => {
          const addedTag = newValue.filter(
            (val) => !tags.some((tag) => `${tag.text} <${tag.id}>` === val)
          );

          if (addedTag.length > 0) {
            // const addedTagText = addedTag[0].split(" <")[0];
            const addedTagId = addedTag[0].split(" <")[1].slice(0, -1);

            handleAddition(addedTagId);
          } else {
            const removedTag = tags.filter(
              (tag) => !newValue.includes(`${tag.text} <${tag.id}>`)
            );
            handleDelete(tags.indexOf(removedTag[0]));
          }
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          console.log("Raw Input Value: ", newInputValue); // Debugging statement
          setInputValue(newInputValue);
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            const tagProps = getTagProps({ index });
            const { key, ...restTagProps } = tagProps; // Destructure to exclude key
            return (
              <Chip
                key={index} // Explicitly pass the key prop here
                variant="outlined"
                label={option}
                {...restTagProps} // Spread the rest of the props without the key
                onDelete={() => handleDelete(index)}
                deleteIcon={
                  <CloseIcon sx={{ color: theme.palette.text.disabled }} />
                }
                sx={{
                  color: isValidEmail(option.split(" <")[1].slice(0, -1))
                    ? theme.palette.text.primary
                    : theme.palette.error.main,
                  borderColor: isValidEmail(option.split(" <")[1].slice(0, -1))
                    ? theme.palette.background.default
                    : theme.palette.error.main,
                  "&:hover": {
                    borderColor: theme.palette.text.disabled, // Change border color on hover
                  },
                }}
              />
            );
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            onBlur={handleBlur} // Add onBlur event
            onKeyDown={(event) => {
              if (event.key === " " && event.target.value.endsWith(" ")) {
                let trimmedValue = event.target.value.trim();
                // Remove trailing dot if present
                if (trimmedValue.endsWith(".")) {
                  trimmedValue = trimmedValue.slice(0, -1);
                }
                console.log("Trimmed Value: ", trimmedValue); // Debugging statement
                if (
                  trimmedValue &&
                  !tags.some(
                    (tag) => `${tag.text} <${tag.id}>` === trimmedValue
                  )
                ) {
                  const trimmedValueText = trimmedValue.split(" <")[0];
                  const trimmedValueId =
                    contacts.nta && contacts.nta[trimmedValueText]
                      ? contacts.nta[trimmedValueText]
                      : trimmedValueText;
                  console.log("Trimmed Value Text: ", trimmedValueText); // Debugging statement
                  console.log("Trimmed Value ID: ", trimmedValueId); // Debugging statement
                  handleAddition(trimmedValueId);
                  setInputValue("");
                  event.preventDefault();
                }
              }
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
              },
            }}
          />
        )}
        ListboxProps={{
          style: {
            maxHeight: "12rem",
            overflow: "auto",
          },
        }}
        ref={autocompleteRef} // Attach the ref to the autocomplete
      />
    </Box>
  );
};

export default EmailTagInput;
