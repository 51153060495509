import { Box, Typography } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import AttachmentIcon from "@mui/icons-material/Attachment";

const ClosedEmailHeader = ({ email, handleToggleOpen }) => {
  const formatDate = (dateStr) => {
    const isISOFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/.test(dateStr);
    const date = new Date(isISOFormat ? dateStr : dateStr + " UTC");
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today);

    yesterday.setDate(today.getDate() - 1);

    if (date >= today) {
      return date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false, // 24-hour format
      });
    } else if (date >= yesterday) {
      return `Yesterday`;
    } else if (date.getFullYear() === now.getFullYear()) {
      return date.toLocaleDateString([], {
        month: "short",
        day: "numeric",
      });
    } else {
      return date.toLocaleDateString([], {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    }
  };

  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        overflow: "hidden",
        cursor: "pointer",
        my: 1,
      }}
      onClick={(e) => {
        if (window.getSelection().toString() === "") {
          handleToggleOpen(email.id);
        }
      }}
    >
      <Typography
        variant="subtitle2"
        fontWeight="bold"
        sx={{
          color: theme.palette.text.primary,
          width: "15%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {email.sender.name}
      </Typography>
      <Box
        sx={{
          width: "65%",
          ml: 4,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          color: theme.palette.text.disabled,
        }}
      >
        <Typography
          variant="body2"
          sx={{
            color: theme.palette.text.primary,
            display: "inline",
          }}
        >
          {email.subject}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            display: "inline",
            ml: 1,
          }}
        >
          {` ${email.preview}`}
        </Typography>
      </Box>
      <Box
        sx={{
          color: theme.palette.text.disabled,
          width: "5%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {email.attachments && email.attachments.length > 0 && (
          <AttachmentIcon />
        )}
      </Box>
      <Typography
        variant="body1"
        sx={{
          width: "10%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          color: theme.palette.text.disabled,
          textAlign: "right",
        }}
      >
        {formatDate(email.received_date)}
      </Typography>
    </Box>
  );
};

export default ClosedEmailHeader;
