import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField,
  Button,
  Box,
  Select,
  MenuItem,
} from "@mui/material";
import { AddCircle, RemoveCircle } from "@mui/icons-material";

const EditableTable = ({ rows, columns, onRowsChange, disabled }) => {
  const handleCellChange = (rowIndex, colIndex, value) => {
    const updatedRows = rows.map((row, rIndex) =>
      rIndex === rowIndex
        ? row.map((cell, cIndex) => (cIndex === colIndex ? value : cell))
        : row
    );
    onRowsChange(updatedRows);
  };

  const handleAddRow = () => {
    const newRow = Array(columns.length).fill("");
    onRowsChange([...rows, newRow]);
  };

  const handleRemoveRow = (index) => {
    const updatedRows = rows.filter((_, rowIndex) => rowIndex !== index);
    onRowsChange(updatedRows);
  };

  return (
    <Box>
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{
          maxHeight: 400,
          borderColor: "text.disabled",
          backgroundColor: "background.default",
        }}
      >
        <Table
          stickyHeader
          size="small"
          variant="outlined"
          sx={{ tableLayout: "fixed", minWidth: "100%" }}
        >
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  width: "2rem",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                #
              </TableCell>
              {columns.map((col, idx) => (
                <TableCell
                  key={idx}
                  sx={{
                    width: col.type === "number" ? "4rem" : "6rem",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {col.name}
                </TableCell>
              ))}
              <TableCell
                align="center"
                sx={{
                  width: "50px",
                }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                <TableCell
                  sx={{
                    width: "50px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {rowIndex + 1}
                </TableCell>
                {columns.map((col, colIndex) => (
                  <TableCell
                    key={colIndex}
                    sx={{
                      width: col.type === "number" ? "100px" : "150px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      padding: "8px",
                    }}
                  >
                    {col.type === "select" ? (
                      <Select
                        value={row[colIndex] || ""}
                        onChange={(e) =>
                          handleCellChange(rowIndex, colIndex, e.target.value)
                        }
                        fullWidth
                        variant="standard"
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        disabled={disabled}
                      >
                        {col.options.map((option, optionIndex) => (
                          <MenuItem key={optionIndex} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <TextField
                        value={row[colIndex] || ""}
                        onChange={(e) =>
                          handleCellChange(rowIndex, colIndex, e.target.value)
                        }
                        variant="standard"
                        fullWidth
                        sx={{
                          backgroundColor: "background.default",
                          width: "100%",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        type={col.type === "number" ? "number" : "text"}
                        disabled={disabled}
                      />
                    )}
                  </TableCell>
                ))}
                <TableCell
                  align="center"
                  sx={{
                    width: "50px",
                  }}
                >
                  <IconButton
                    color="error"
                    onClick={() => handleRemoveRow(rowIndex)}
                    disabled={disabled}
                  >
                    <RemoveCircle />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box display="flex" justifyContent="center" sx={{ m: 1 }}>
          <IconButton
            color="success"
            onClick={handleAddRow}
            disabled={disabled}
          >
            <AddCircle />
          </IconButton>
        </Box>
      </TableContainer>
    </Box>
  );
};

export default EditableTable;
