import React, {
  useEffect,
  useRef,
  forwardRef,
  useState,
  useImperativeHandle,
} from "react";
import EmailContent from "./EmailContent";
import ClosedEmailHeader from "./SingleEmailHeaders/ClosedEmailHeader";
import OpenEmailHeader from "./SingleEmailHeaders/OpenEmailHeader";
import DraftPanel from "../../Drafting/DraftPanel";
import { getAttachmentDownloadUrl } from "../../../api/email";

import { Box, Divider, Typography, Card } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const EmailCard = ({
  replySubject,
  setReplySubject,
  replyBody,
  setReplyBody,
  toTags,
  setToTags,
  ccTags,
  setCcTags,
  bccTags,
  setBccTags,
  attachments,
  setAttachments,
  contacts,
  email,
  isLast,
  user,
  updateConversation,
  conversation,
  moveConversation,
  request,
  draftingEmailId, // Destructure the drafting email ID
  setDraftingEmailId,
  resetDrafting,
}) => {
  const [isOpen, setIsOpen] = useState(isLast);
  const [isDrafting, setIsDrafting] = useState(null);
  const theme = useTheme();
  const draftRef = useRef(null);
  const bodyInputRef = useRef(null);

  useEffect(() => {
    console.log(
      "update isDrafting in email card! ",
      email.id.slice(-10, -4),
      draftingEmailId ? draftingEmailId.slice(-10, -4) : "no id"
    );
    setIsDrafting(email.id === draftingEmailId);
    console.log("isDrafting in email card! ", isDrafting);
    if (email.id === draftingEmailId) {
      setIsOpen(true);
    }
  }, [draftingEmailId]);

  useEffect(() => {
    if (isDrafting && draftRef.current) {
      console.log("draftRef.current", draftRef.current);
      draftRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });

      setTimeout(() => {
        if (bodyInputRef.current) {
          bodyInputRef.current.focus();
        }
      }, 700);
    }
  }, [isDrafting]);

  // useEffect(() => {
  //   let timer;
  //   if (email.labels.includes("UNREAD")) {
  //     timer = setTimeout(async () => {
  //       try {
  //         moveConversation(conversation, "read");
  //       } catch (error) {
  //         console.error("Error:", error);
  //       }
  //     }, 1000);
  //   }
  //   return () => clearTimeout(timer);
  // }, [
  //   isOpen,
  //   email,
  //   updateConversation,
  //   user,
  //   conversation,
  //   moveConversation,
  // ]);

  const handleToggleOpen = (id) => {
    setIsOpen((prev) => !prev);
  };

  const handleReply = (email, type) => {
    console.log("user", user);
    console.log("contacts", contacts);
    console.log("resetDrafting", resetDrafting);
    console.log("draftingEmailId", draftingEmailId);
    console.log("Current email id", email.id);
    console.log("isDrafting", isDrafting);

    let userEmail = user.email;
    let toRecipients = [];
    let ccRecipients = [];
    const isOriginalSender = email.sender.address === userEmail;

    if (type === "reply") {
      if (isOriginalSender) {
        toRecipients = email.to_recipients;
      } else {
        toRecipients = [email.sender];
      }
    } else if (type === "replyAll") {
      if (isOriginalSender) {
        toRecipients = email.to_recipients;
        ccRecipients = email.cc_recipients;
      } else {
        toRecipients = [email.sender, ...email.to_recipients].filter(
          (recipient) => recipient.address !== userEmail
        );

        ccRecipients = email.cc_recipients.filter(
          (recipient) => recipient.address !== userEmail
        );
      }
    }
    setReplySubject(`Re: ${email.subject}`);
    setReplyBody(``);
    console.log("In EMAIL CARD setting draftingEmailId to ", email.id);
    setDraftingEmailId(email.id);
    setToTags(
      toRecipients.map((recipient) => ({
        id: recipient.address,
        text: recipient.name,
      }))
    );
    setCcTags(
      ccRecipients.map((recipient) => ({
        id: recipient.address,
        text: recipient.name,
      }))
    );
    setBccTags([]); // Assuming Bcc is empty initially
    // Trigger drafting mode by setting draftingEmailId in useRequest
    // This should be handled by useRequest, so no need to set local state
  };

  return (
    <Card
      key={email.id}
      sx={{
        mb: 1,
        width: "calc(100% - 4px)", // Adjust width to account for the border
        borderLeft: isOpen ? `4px solid ${theme.palette.info.main}` : ``, // Use transparent border when closed
      }}
      variant="outlined"
    >
      <Box sx={{ m: 1, mx: 2 }}>
        {!isOpen && (
          <ClosedEmailHeader
            email={email}
            handleToggleOpen={handleToggleOpen}
          />
        )}

        {isOpen && (
          <>
            <OpenEmailHeader
              email={email}
              onReply={() => handleReply(email, "reply")}
              onReplyAll={() => handleReply(email, "replyAll")}
              onForward={() => handleReply(email, "forward")}
              handleToggleOpen={handleToggleOpen}
            />
            <Divider sx={{ mb: 2 }} />
            <EmailContent
              email={email}
              isOpen={isOpen}
              attachments={email.attachments}
            />
            {email.attachments && email.attachments.length > 0 && (
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {email.attachments.map((attachment) => (
                  <Box
                    key={attachment.id}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid",
                      borderColor: theme.palette.divider,
                      borderRadius: 1,
                      p: 1,
                      mx: 1,
                      mb: 1,
                      backgroundColor: theme.palette.background.dark,
                      width: "10rem",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      window.open(
                        getAttachmentDownloadUrl(email.id, attachment.id),
                        "_blank"
                      )
                    }
                  >
                    <Typography variant="body2" noWrap>
                      {attachment.name.length > 20
                        ? `${attachment.name.slice(
                            0,
                            11
                          )}...${attachment.name.slice(-6)}`
                        : attachment.name}
                    </Typography>
                    <Typography variant="body2">
                      {`${(attachment.size / 1024).toFixed(2)} KB`}
                    </Typography>
                  </Box>
                ))}
              </Box>
            )}

            {isDrafting && (
              <>
                <Divider sx={{ my: 1 }} />
                <Box
                  sx={{
                    p: 1,
                    height: "100%",
                  }}
                  variant="outlined"
                  ref={draftRef}
                >
                  <DraftPanel
                    contacts={contacts}
                    resetDrafting={resetDrafting} // Use the reset function
                    email={email}
                    conversation_id={conversation.conversation_id}
                    replySubject={replySubject}
                    setReplySubject={setReplySubject}
                    replyBody={replyBody}
                    setReplyBody={setReplyBody}
                    toTags={toTags}
                    setToTags={setToTags}
                    ccTags={ccTags}
                    setCcTags={setCcTags}
                    bccTags={bccTags}
                    setBccTags={setBccTags}
                    bodyInputRef={bodyInputRef}
                    attachments={attachments}
                    setAttachments={setAttachments}
                    request={request}
                  />
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </Card>
  );
};

export default EmailCard;
