import { Box, Typography, IconButton } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import ReplyIcon from "@mui/icons-material/Reply";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import ForwardIcon from "@mui/icons-material/Forward";

const OpenEmailHeader = ({
  email,
  onReply,
  onReplyAll,
  onForward,
  handleToggleOpen,
}) => {
  const theme = useTheme();

  const formatDate = (dateStr) => {
    const isISOFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/.test(dateStr);
    const date = new Date(isISOFormat ? dateStr : dateStr + " UTC");

    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false, // 24-hour format
      timeZoneName: "short",
    };
    return date.toLocaleString("en-US", options).replace(" at", " at");
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        my: 1,
        cursor: "pointer",
      }}
      onClick={(e) => {
        if (window.getSelection().toString() === "") {
          handleToggleOpen(email.id);
        }
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          variant="body2"
          sx={{
            color: theme.palette.text.disabled,
            mr: 1,
            width: "8%",
            justifyContent: "center",
            textAlign: "left",
          }}
        >
          From:
        </Typography>
        <Typography
          variant="subtitle2"
          fontWeight="bold"
          sx={{
            flex: 1,
            color: theme.palette.text.primary,
          }}
        >
          {email.sender.name}{" "}
          <Typography
            component="span"
            variant="body1"
            sx={{ color: theme.palette.text.disabled }}
          >{`<${email.sender.address}>`}</Typography>
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
        <Typography
          variant="body2"
          sx={{
            color: theme.palette.text.disabled,
            mr: 1,
            width: "8%",
          }}
        >
          To:
        </Typography>
        <Typography
          variant="body2"
          sx={{
            flex: 1,
            color: theme.palette.text.primary,
          }}
        >
          {email.to_recipients.map((recipient, index) => (
            <span key={index}>
              {recipient.name}{" "}
              <Typography
                component="span"
                variant="body1"
                sx={{ color: theme.palette.text.disabled }}
              >{`<${recipient.address}>`}</Typography>
              {index < email.to_recipients.length - 1 && ", "}
            </span>
          ))}
        </Typography>
        <Box sx={{ ml: "auto", display: "flex", gap: 1 }}>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              onReply();
            }}
          >
            <ReplyIcon fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              onReplyAll();
            }}
          >
            <ReplyAllIcon fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              onForward();
            }}
          >
            <ForwardIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      {email.cc_recipients.length > 0 && (
        <Box sx={{ display: "flex", mt: 1 }}>
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.text.disabled,
              mr: 1,
              width: "8%",
            }}
          >
            Cc:
          </Typography>
          <Typography variant="body2" sx={{ flex: 1 }}>
            {email.cc_recipients.map((recipient, index) => (
              <span key={index}>
                {recipient.name}{" "}
                <Typography
                  component="span"
                  variant="body2"
                  sx={{ color: theme.palette.text.disabled }}
                >{`<${recipient.address}>`}</Typography>
                {index < email.cc_recipients.length - 1 && ", "}
              </span>
            ))}
          </Typography>
        </Box>
      )}
      {email.bcc_recipients.length > 0 && (
        <Box sx={{ display: "flex", mt: 1 }}>
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.text.disabled,
              mr: 1,
              width: "8%",
            }}
          >
            Bcc:
          </Typography>
          <Typography variant="body2" sx={{ flex: 1 }}>
            {email.bcc_recipients
              .map((recipient) => `${recipient.name} <${recipient.address}>`)
              .join(", ")}
          </Typography>
        </Box>
      )}
      <Typography
        variant="body1"
        sx={{
          textAlign: "left", // Align date to the left
          color: theme.palette.text.disabled,

          mt: 1,
        }}
      >
        {formatDate(email.received_date)}
      </Typography>
    </Box>
  );
};

export default OpenEmailHeader;
