import React, { useState } from "react";
import { Box, IconButton } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DOMPurify from "dompurify";

// Configure DOMPurify to allow images
const sanitizeOptions = {
  ALLOWED_TAGS: [
    "img",
    "div",
    "span",
    "a",
    "p",
    "br",
    "b",
    "i",
    "u",
    "strong",
    "em",
    "blockquote",
    "code",
    "pre",
    "ul",
    "ol",
    "li",
    "table",
    "thead",
    "tbody",
    "tr",
    "th",
    "td",
  ],
  ALLOWED_ATTR: [
    "src",
    "href",
    "alt",
    "title",
    "width",
    "height",
    "style",
    "class",
    "id",
    "name",
    "target",
    "rel",
  ],
};

// Function to replace cid references with actual attachment URLs
const replaceCidReferences = (content, attachments) => {
  return content.replace(/src="cid:(.*?)"/g, (match, cid) => {
    const attachment = attachments.find((att) => att.cid === cid);
    return attachment ? `src="${attachment.url}"` : match;
  });
};

// Updated function to split email content
const splitEmailContent = (content) => {
  const regex = /(<div class="gmail_quote">.*?<\/div>)/s;
  const parts = content.split(regex);
  return {
    mainContent: parts[0] || content, // In case there's no history
    history: parts.slice(1).join("") || "", // Join the rest as history
  };
};

const EmailContent = ({ email, isOpen, attachments }) => {
  const [showHistory, setShowHistory] = useState(false);
  const { mainContent, history } = splitEmailContent(email.content);

  // Replace cid references with actual attachment URLs
  const contentWithAttachments = replaceCidReferences(mainContent, attachments);
  const historyWithAttachments = replaceCidReferences(history, attachments);

  // Sanitize the HTML content with options
  const sanitizedMainContent = DOMPurify.sanitize(
    contentWithAttachments,
    sanitizeOptions
  );
  const sanitizedHistory = DOMPurify.sanitize(
    historyWithAttachments,
    sanitizeOptions
  );

  const toggleHistory = (e) => {
    e.stopPropagation(); // Prevent event from bubbling up
    setShowHistory((prevShowHistory) => !prevShowHistory);
  };

  return (
    <Box sx={{ mt: 2 }}>
      <style>
        {`
          ul, ol {
            list-style: initial; /* Ensure default list styles are applied */
            margin-left: 40px; /* Add more margin for better indentation */
            padding-left: 20px; /* Add padding for additional indentation */
          }
          li {
            margin-bottom: 5px; /* Add some space between list items */
          }
          @media (max-width: 600px) {
            ul, ol {
              margin-left: 20px;
              padding-left: 10px;
            }
          }
        `}
      </style>
      <Box
        sx={{
          display: isOpen ? "block" : "none",
          overflow: "auto",
        }}
        dangerouslySetInnerHTML={{ __html: sanitizedMainContent }}
        aria-label="Email main content"
      />
      {history && (
        <Box
          sx={{ mt: 2, textAlign: "left" }}
          onClick={(e) => e.stopPropagation()}
        >
          <IconButton onClick={toggleHistory}>
            <MoreHorizIcon />
          </IconButton>
        </Box>
      )}
      {showHistory && (
        <Box
          sx={{
            display: isOpen ? "block" : "none",
            marginTop: "10px",
          }}
          dangerouslySetInnerHTML={{ __html: sanitizedHistory }}
        />
      )}
    </Box>
  );
};

export default EmailContent;
