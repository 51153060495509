import React from "react";
import {
  Box,
  Fade,
  Typography,
  CircularProgress,
  Button,
  AlertTitle,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { marked } from "marked";
import FileAttachment from "./File/FileAttachment";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ToolMessage from "./ToolMessage";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";

const MessageList = ({
  messages,
  toolRunningMessages,
  theme,
  messagesEndRef,
  formLoadingCount,
  chatbotLoading,
  sendMessage,
  submittedSuccessfully,
  initialOptions,
}) => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        overflowY: "auto", // Ensures scrollbar appears only when needed
        py: 2,
      }}
    >
      {messages.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center", // Center vertically
            height: "80%", // Ensure the container takes full height
            overflow: "hidden",
            pt: 5,
          }}
        >
          {initialOptions.map((option, index) => (
            <Button
              key={index}
              onClick={() => sendMessage(option.message, [])}
              disabled={chatbotLoading || submittedSuccessfully}
              variant="outlined"
              sx={{
                p: 2,
                m: 1,
                display: "flex",
                flexDirection: "row", // Change to row for horizontal layout
                alignItems: "center", // Center items vertically
                justifyContent: "flex-start",
                width: "80%", // Set width to 75%
                minHeight: "5rem", // Set height to 5rem
                textTransform: "none", // Prevent text capitalization
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                {option.icon}
              </Box>
              <Typography
                variant="body1"
                sx={{
                  pl: 2, // Add padding-left for spacing between icon and text
                  textAlign: "left",
                }}
              >
                {option.description}
              </Typography>
            </Button>
          ))}
        </Box>
      ) : (
        messages.map((msg, index) => (
          <Fade in={true} timeout={1000} key={index}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",

                alignItems:
                  msg.role === "assistant" && !msg.tool_calls
                    ? "flex-start"
                    : msg.role === "user"
                    ? "flex-end"
                    : "center",
              }}
            >
              {msg.role === "assistant" && msg.tool_calls ? (
                Array.isArray(msg.tool_calls) && msg.tool_calls.length > 0 ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column", // Change to column for vertical alignment
                      color: theme.palette.text.disabled,
                      my: 0,
                    }}
                  >
                    {msg.tool_calls.map((tool, idx) => {
                      const toolMessage = toolRunningMessages[tool.name];
                      return toolMessage ? (
                        <Box key={idx} sx={{ textAlign: "center", my: 1 }}>
                          <Typography variant="body2" fontWeight="bold">
                            {toolMessage}
                          </Typography>
                        </Box>
                      ) : null;
                    })}
                  </Box>
                ) : null
              ) : msg.role === "assistant" && msg.flag === "notification" ? (
                <Box
                  sx={{
                    display: "flex",
                    color: theme.palette.text.disabled,
                    width: "100%",
                    justifyContent: "center",
                    my: 2,
                  }}
                >
                  <Typography variant="body2" fontWeight="bold">
                    {msg.content}
                  </Typography>
                </Box>
              ) : msg.role === "tool" ? (
                toolRunningMessages[msg.tool_name] ? (
                  <ToolMessage msg={msg} />
                ) : null
              ) : (
                <Typography
                  variant="body"
                  sx={{
                    backgroundColor:
                      msg.role === "assistant"
                        ? theme.palette.primary.main
                        : theme.palette.background.dark,
                    color:
                      msg.role === "assistant"
                        ? theme.palette.background.default
                        : theme.palette.text.primary,
                    py: 1,
                    px: 2,
                    my: 1,
                    borderRadius: "12px",
                    maxWidth: "75%",
                  }}
                >
                  <div
                    className="markdown-content"
                    dangerouslySetInnerHTML={{
                      __html: marked(msg.content),
                    }}
                  />
                </Typography>
              )}
              {msg.role === "user" && msg.files && msg.files.length > 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    mt: 0,
                    py: 1,
                  }}
                >
                  {msg.files.map((file, idx) => (
                    <FileAttachment
                      key={idx}
                      file={file}
                      deletable={false}
                      theme={theme}
                    />
                  ))}
                </Box>
              ) : null}
            </Box>
          </Fade>
        ))
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          pt: 5,
          height: 48,
        }}
      >
        {chatbotLoading && <CircularProgress size={48} />}
      </Box>
      <div ref={messagesEndRef} />
    </Box>
  );
};

export default MessageList;
