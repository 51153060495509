import { Box, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTheme } from "@mui/material/styles";

import EmailCard from "./EmailView/EmailCard";
import { useEffect, useCallback, useRef } from "react";

const ConversationView = ({
  replySubject,
  setReplySubject,
  replyBody,
  setReplyBody,
  toTags,
  setToTags,
  ccTags,
  setCcTags,
  bccTags,
  setBccTags,
  attachments,
  setAttachments,
  request,
  user,
  conversation,
  contacts,
  onBack,
  updateConversation,
  moveConversation,
  draftingEmailId,
  setDraftingEmailId,
  resetDrafting,
}) => {
  const theme = useTheme();

  const threadEmails = (conversation.emails || []).sort(
    (a, b) => new Date(a.received_date) - new Date(b.received_date)
  );

  const subject =
    threadEmails.length > 0 ? threadEmails[0].subject : "Email Thread";

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onBack();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onBack]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%", // Ensure the Box takes full height
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          px: 2,
          pb: 1,
          pt: 2,

          position: "sticky",
          top: 0,
          width: "100%",
          backgroundColor: "background.default",
          zIndex: 20,
        }}
      >
        <IconButton onClick={onBack}>
          <ArrowBackIcon />
        </IconButton>
        <Typography
          variant="h4"
          sx={{
            ml: 2,
            fontWeight: "normal",
            color: theme.palette.text.primary,
          }}
        >
          {subject}
        </Typography>
      </Box>
      <Box
        sx={{
          p: 2,
          flexGrow: 1, // Allow the box to grow and take available space
          overflowY: "auto", // Enable vertical scrolling
        }}
      >
        {threadEmails.map((email, index) => (
          <EmailCard
            replySubject={replySubject}
            setReplySubject={setReplySubject}
            replyBody={replyBody}
            setReplyBody={setReplyBody}
            toTags={toTags}
            setToTags={setToTags}
            ccTags={ccTags}
            setCcTags={setCcTags}
            bccTags={bccTags}
            setBccTags={setBccTags}
            attachments={attachments}
            setAttachments={setAttachments}
            request={request}
            contacts={contacts}
            key={email.id}
            email={email}
            conversation={conversation}
            isLast={email === threadEmails[threadEmails.length - 1]}
            user={user}
            updateConversation={updateConversation}
            moveConversation={moveConversation}
            draftingEmailId={draftingEmailId}
            setDraftingEmailId={setDraftingEmailId}
            resetDrafting={resetDrafting}
          />
        ))}
      </Box>
    </Box>
  );
};

export default ConversationView;
