import React, { useState } from "react";
import { Box, Typography, Modal, Select, MenuItem, Alert } from "@mui/material";
import FlowDiagram from "../../../components/Diagram/FlowDiagram"; // Adjust the import path if necessary
import { useTheme } from "@mui/material/styles";
import { updateRequestStatus } from "../../../api/request";

const Overview = ({ user, steps, request, getRequestDetails }) => {
  const theme = useTheme();
  const [selectedNode, setSelectedNode] = useState(null);
  const [error, setError] = useState(null);

  const handleNodeClick = (nodeData) => {
    console.log("Node data: ", nodeData);
    if (
      nodeData.approver.email === user.email ||
      (user.roles &&
        user.roles.some((role) => role.permissions.includes("CHANGE_STATUS")))
    ) {
      setSelectedNode(nodeData);
    } else {
      // alert("You are not authorized to change this step.");
    }
  };

  const handleClose = () => {
    setSelectedNode(null);
    setError(null);
  };

  const handleStatusChange = async (event) => {
    if (!selectedNode) return;

    const newStatus = event.target.value;
    const updatedNode = { ...selectedNode, status: newStatus };
    setSelectedNode(updatedNode);

    try {
      const res = await updateRequestStatus(
        request.id,
        selectedNode.title,
        newStatus
      );
      console.log(res);
      getRequestDetails();
    } catch (error) {
      console.error("Error updating status:", error);
      // Extract error message from response if available
      const errorMessage =
        error.response?.data?.error ||
        "An unexpected error occurred while updating the status.";
      setError(errorMessage);
    }
  };

  return (
    <Box>
      <FlowDiagram
        user={user}
        steps={steps}
        theme={theme}
        onNodeClick={handleNodeClick}
      />
      <Modal open={!!selectedNode} onClose={handleClose}>
        <Box
          sx={{
            padding: 4,
            margin: "auto",
            backgroundColor: "background.default",
            mt: 5,
            width: "30rem", // Responsive width
          }}
        >
          <Typography variant="h5" sx={{ mb: 2 }}>
            {selectedNode?.title}
          </Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>
            <strong>Approver:</strong> {selectedNode?.approver.name}
          </Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>
            <strong>Date:</strong> {selectedNode?.date}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: 2,
            }}
          >
            <Select
              sx={{
                width: "15rem",
                height: "2rem",
                border: "1px solid",
                borderColor: "divider",
              }}
              label="Status"
              value={selectedNode?.status || ""}
              onChange={handleStatusChange}
              fullWidth
            >
              <MenuItem value="new">New</MenuItem>
              <MenuItem value="in_progress">In Progress</MenuItem>
              <MenuItem value="complete">Complete</MenuItem>
              <MenuItem value="rejected">Rejected</MenuItem>
              <MenuItem value="cancelled">Cancelled</MenuItem>
              <MenuItem value="bypassed">Bypassed</MenuItem>
            </Select>
          </Box>
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default Overview;
