import React, { useState } from "react";
import {
  Box,
  Avatar,
  Typography,
  Divider,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  Task,
  Logout,
  Settings as SettingsIcon,
  Dashboard as DashboardIcon,
} from "@mui/icons-material"; // Import icons
import { delegateTask } from "../api/request"; // Ensure the correct path
import { useNavigate, useLocation } from "react-router-dom"; // Import useNavigate and useLocation

const DrawerComponent = ({
  drawerOpen,
  toggleDrawer,
  user,
  handlePauseToggle,
  handleLogout,
}) => {
  const [delegateModalOpen, setDelegateModalOpen] = useState(false);
  const [delegateEmail, setDelegateEmail] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // "success" or "error"
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate(); // Initialize useNavigate
  const location = useLocation(); // Initialize useLocation

  const handleDelegateTask = () => {
    setDelegateModalOpen(true);
  };

  const handleDelegateConfirm = async () => {
    try {
      setLoading(true);

      const response = await delegateTask(delegateEmail);
      setSnackbarMessage(response.message || "Tasks delegated successfully.");
      setSnackbarSeverity("success");
    } catch (error) {
      setSnackbarMessage(
        error.response?.data?.error || "An error occurred during delegation."
      );
      setSnackbarSeverity("error");
    } finally {
      setLoading(false);
      //   setDelegateEmail("");
      setSnackbarOpen(true);
      setDelegateModalOpen(false); // Close modal after action
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setDelegateEmail(email);
    if (email && !validateEmail(email)) {
      setEmailError("Invalid email format");
    } else {
      setEmailError("");
    }
  };

  // Define the drawer items with their respective routes
  const drawerItems = [
    {
      text: "All Requests",
      icon: <DashboardIcon />,
      route: "/dashboard/all_requests",
      onClick: () => navigate("/dashboard/all_requests"),
      isNavigation: true,
    },
    // {
    //   text: "Delegate Task",
    //   icon: <Task />,
    //   route: "#",
    //   onClick: handleDelegateTask,
    //   isNavigation: false,
    // },

    ...(user?.roles?.some((r) => r.name === "admin")
      ? [
          {
            text: "Settings",
            icon: <SettingsIcon />,
            route: "/dashboard/settings",
            onClick: () => navigate("/dashboard/settings"),
            isNavigation: true,
          },
        ]
      : []),
    {
      text: "Logout",
      icon: <Logout />,
      route: "/logout",
      onClick: handleLogout,
      isNavigation: false,
      isLogout: true,
    },
  ];

  return (
    <>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        sx={{
          p: 0,
        }}
      >
        {user && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: 250,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                px: 2,
                py: 2,
                width: "100%",
              }}
            >
              <Avatar sx={{ mr: 2 }}>{user.name.charAt(0)}</Avatar>
              <Typography
                variant="body1"
                sx={{
                  color: "text.disabled",
                }}
              >
                {user.name}
              </Typography>
            </Box>

            <Divider sx={{ width: "100%" }} />

            <List sx={{ width: "100%" }}>
              {drawerItems.map((item) => {
                const isSelected =
                  item.isNavigation && location.pathname === item.route;

                return (
                  <ListItem key={item.text} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        item.onClick();
                        if (item.isNavigation) {
                          toggleDrawer(false)(); // Close drawer after navigation
                        }
                      }}
                      selected={isSelected}
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: "background.dark",
                          color: "primary.main",
                          "& .MuiListItemIcon-root": {
                            color: "primary.main",
                          },
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          color: isSelected ? "primary.main" : "text.primary",
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText primary={item.text} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        )}
      </Drawer>

      {/* Delegate Task Modal */}
      <Modal
        open={delegateModalOpen}
        onClose={() => setDelegateModalOpen(false)}
        aria-labelledby="delegate-task-modal-title"
        aria-describedby="delegate-task-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: "50%" },
            bgcolor: "background.dark",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            id="delegate-task-modal-title"
            variant="h5"
            // component="h2"
          >
            Delegate Tasks
          </Typography>
          <Typography id="modal-modal-description" sx={{ my: 2 }}>
            You can delegate all of your open and future tasks to another user
            by entering their email (completed tasks will not change).{" "}
          </Typography>
          <Typography sx={{ my: 2, fontWeight: "bold" }}>
            Please note that to have the tasks re-delegated back to you, they
            will have to do the same thing in their dashboard using your email
            address.
          </Typography>
          <TextField
            fullWidth
            label="Delegatee Email"
            margin="normal"
            value={delegateEmail}
            onChange={handleEmailChange}
            error={!!emailError}
            helperText={emailError}
            sx={{
              backgroundColor: "background.default",
              borderRadius: "12px",
            }}
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant="contained"
              onClick={handleDelegateConfirm}
              disabled={!delegateEmail || !!emailError || loading}
            >
              {loading ? "Loading..." : "Confirm"}
            </Button>
          </Box>
          {snackbarOpen && (
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
            >
              <Alert
                onClose={handleCloseSnackbar}
                severity={snackbarSeverity}
                sx={{ width: "100%" }}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default DrawerComponent;
