import React, { useState, useEffect } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTheme } from "@mui/material/styles";
import DraftPanel from "./DraftPanel";

const ComposeEmailView = ({
  onBack,
  contacts,
  request,
  replySubject,
  setReplySubject,
  replyBody,
  setReplyBody,
  toTags,
  setToTags,
  ccTags,
  setCcTags,
  bccTags,
  setBccTags,
  attachments,
  setAttachments,
  resetDrafting,
}) => {
  const theme = useTheme();

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onBack();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onBack]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          px: 2,
          pb: 1,
          pt: 2,

          position: "sticky",
          top: 0,
          width: "100%",

          backgroundColor: "background.default",
          zIndex: 20,
        }}
      >
        <IconButton onClick={onBack} sx={{ mr: 1 }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography
          variant="h4"
          sx={{
            color: theme.palette.text.primary,
          }}
        >
          New Email
        </Typography>
      </Box>
      <Box
        sx={{
          p: 1,
          height: "100%",
        }}
        variant="outlined"
      >
        <DraftPanel
          contacts={contacts}
          replySubject={replySubject}
          setReplySubject={setReplySubject}
          replyBody={replyBody}
          setReplyBody={setReplyBody}
          toTags={toTags}
          setToTags={setToTags}
          ccTags={ccTags}
          setCcTags={setCcTags}
          bccTags={bccTags}
          setBccTags={setBccTags}
          attachments={attachments} // Pass attachments state
          setAttachments={setAttachments} // Pass setAttachments function
          request={request}
          resetDrafting={resetDrafting}
        />
      </Box>
    </Box>
  );
};

export default ComposeEmailView;
