import React from "react";
import { Box, Chip } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DescriptionIcon from "@mui/icons-material/Description";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

const FileAttachment = ({ file, onDelete, deletable }) => {
  let icon;
  if (file.name.endsWith(".pdf")) {
    icon = <PictureAsPdfIcon />;
  } else if (file.name.endsWith(".doc") || file.name.endsWith(".docx")) {
    icon = <DescriptionIcon />;
  } else {
    icon = <InsertDriveFileIcon />;
  }

  return (
    <Chip
      icon={icon}
      label={file.name}
      component="a"
      variant="outlined"
      color="primary"
      onDelete={deletable ? onDelete : undefined}
      sx={{ mt: 1, width: "fit-content", borderWidth: 3 }}
    />
  );
};

export default FileAttachment;
