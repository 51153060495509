import React, { useMemo } from "react";
import {
  List,
  ListItemText,
  Box,
  Typography,
  Grid,
  Chip,
  ListItemButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { format, isValid } from "date-fns";
import { useNavigate } from "react-router-dom";
import SailingIcon from "@mui/icons-material/Sailing";
import debounce from "lodash.debounce";

const RequestsListPage = ({
  requests,
  user,
  searchQuery,
  handleSearch,
  statusFilter,
  handleStatusFilter,
  sortOrder,
  changeSortOrder,
  loading, // Ensure loading state is passed
}) => {
  const navigate = useNavigate();

  const formattedRequests = Object.entries(requests).map(([id, request]) => ({
    id,
    ...request,
  }));

  const handleSelectRequest = (request) => {
    navigate(`/dashboard/request/${request.id}`);
  };

  const handleSortChange = (event) => {
    const selectedSort = event.target.value;
    const newSortOrder = selectedSort === "newest" ? "desc" : "asc";
    changeSortOrder(newSortOrder);
  };

  // Debounced Search Handler
  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        handleSearch(value);
      }, 500),
    [handleSearch]
  );

  const handleSearchInputChange = (e) => {
    debouncedSearch(e.target.value);
  };

  return (
    <Box>
      {user && user.paused ? (
        <Box
          sx={{
            width: "100%",
            textAlign: "center",
            color: "text.disabled",
            mt: 4,
          }}
        >
          <SailingIcon sx={{ fontSize: 100, py: 2 }} />{" "}
          {/* Adjust size as needed */}
          <Typography variant="h4">Account is Paused</Typography>
        </Box>
      ) : (
        <>
          {/* Search and Filter Bar */}
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 2,
              mt: 4,
              px: 4,
              py: 1,

              alignItems: "center",

              borderRadius: 2,
            }}
          >
            {/* Search Bar */}
            <TextField
              label="Search Requests"
              variant="outlined"
              defaultValue={searchQuery}
              onChange={handleSearchInputChange}
              sx={{ flex: 1, minWidth: 200 }}
            />

            {/* Status Filter */}
            <FormControl variant="outlined" sx={{ minWidth: 150 }}>
              <InputLabel>Status</InputLabel>
              <Select
                value={statusFilter}
                onChange={(e) => handleStatusFilter(e.target.value)}
                label="Status"
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                <MenuItem value="new">New</MenuItem>
                <MenuItem value="in_progress">In Progress</MenuItem>
                <MenuItem value="complete">Complete</MenuItem>
                <MenuItem value="rejected">Rejected</MenuItem>
                {/* Add more status options as needed */}
              </Select>
            </FormControl>

            {/* Sort Select */}
            <FormControl variant="outlined" sx={{ minWidth: 150 }}>
              <InputLabel>Sort By</InputLabel>
              <Select
                value={sortOrder === "desc" ? "newest" : "oldest"}
                onChange={handleSortChange}
                label="Sort By"
              >
                <MenuItem value="newest">Newest</MenuItem>
                <MenuItem value="oldest">Oldest</MenuItem>
              </Select>
            </FormControl>
          </Box>

          {/* Loading Indicator */}
          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              {/* Requests List */}
              <List>
                {formattedRequests.length > 0 ? (
                  formattedRequests.map((request) => (
                    <ListItemButton
                      key={request.id}
                      onClick={() => handleSelectRequest(request)}
                      sx={{
                        "&:hover": {
                          backgroundColor: "background.dark",
                          cursor: "pointer",
                        },
                        px: 4,
                      }}
                    >
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        sx={{ height: "100%" }}
                      >
                        <Grid item sx={{ width: "40%" }}>
                          <ListItemText
                            primary={
                              <Typography noWrap variant="subtitle1">
                                {request.title}
                              </Typography>
                            }
                          />
                        </Grid>
                        <Grid item sx={{ width: "25%" }}>
                          <ListItemText
                            primary={
                              <Typography noWrap color="text.secondary">
                                {request.requester_name}
                              </Typography>
                            }
                          />
                        </Grid>
                        <Grid item sx={{ width: "20%", textAlign: "center" }}>
                          <Chip
                            label={request.status}
                            variant="outlined"
                            sx={{
                              width: "100%",
                            }}
                            color={
                              request.status === "in_progress"
                                ? "warning"
                                : request.status === "complete"
                                ? "success"
                                : request.status === "new"
                                ? "info"
                                : request.status === "rejected" ||
                                  request.status === "cancelled"
                                ? "error"
                                : "info"
                            }
                          />
                        </Grid>
                        <Grid item sx={{ width: "15%", textAlign: "right" }}>
                          <ListItemText
                            primary={
                              isValid(new Date(request.submitted_at))
                                ? format(
                                    new Date(request.submitted_at),
                                    "MMM d, yyyy"
                                  )
                                : "N/A"
                            }
                          />
                        </Grid>
                      </Grid>
                    </ListItemButton>
                  ))
                ) : (
                  <Box sx={{ width: "100%", textAlign: "center", mt: 4 }}>
                    <Typography variant="h6">No requests found.</Typography>
                  </Box>
                )}
              </List>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default RequestsListPage;
