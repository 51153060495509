const isConditionMet = (formConfig, condition) => {
  const { field, section, values, min, max } = condition;
  const sectionData = formConfig.find((s) => s.title === section);
  if (!sectionData) return false;
  const fieldData = sectionData.fields.find((f) => f.name === field);
  if (values) {
    return values.includes(fieldData.value);
  }
  if (min_value && fieldData.value <= min_value) {
    return false;
  }
  if (max_value && fieldData.value >= max_value) {
    return false;
  }
  return true;
};

export const getVisibleSections = (formConfig) => {
  return formConfig.filter((section) => {
    if (section.conditions && section.conditions.length > 0) {
      let or_condition_met = false;
      for (let or_condition of section.conditions) {
        let and_condition_met = true;
        for (let and_condition of or_condition) {
          if (!isConditionMet(formConfig, and_condition)) {
            and_condition_met = false;
            break;
          }
        }
        if (and_condition_met) {
          or_condition_met = true;
          break;
        }
      }
      if (or_condition_met) {
        return true;
      }
    }
    return false;
  });
};

export const getErrors = (formConfig) => {
  const newErrors = {};
  const newErrorFields = [];
  const visibleSections = getVisibleSections(formConfig);

  console.log("visibleSections: ", visibleSections);
  visibleSections.forEach((section, sectionIndex) => {
    section.fields.forEach((field, fieldIndex) => {
      if (field.type === "table") {
        // Check each cell in the table
        field.value.forEach((row, rowIndex) => {
          row.forEach((cell, colIndex) => {
            if (!cell) {
              if (!newErrors[sectionIndex]) newErrors[sectionIndex] = {};
              if (!newErrors[sectionIndex][fieldIndex])
                newErrors[sectionIndex][fieldIndex] = {};
              if (!newErrors[sectionIndex][fieldIndex][rowIndex])
                newErrors[sectionIndex][fieldIndex][rowIndex] = {};
              newErrors[sectionIndex][fieldIndex][rowIndex][colIndex] = true;
              newErrorFields.push(
                `${field.name} Row ${rowIndex + 1} Column ${colIndex + 1}`
              );
            }
          });
        });
      } else if (
        !field.name.toLowerCase().includes("optional") &&
        !field.value
      ) {
        if (!newErrors[sectionIndex]) newErrors[sectionIndex] = {};
        newErrors[sectionIndex][fieldIndex] = true;
        newErrorFields.push(field.name);
      }
    });
  });

  return { newErrors, newErrorFields };
};
