import axios from "axios";

// Define your backend URL from environment variables
export const BACKEND_URL = `${process.env.REACT_APP_API_URL}`;
// export const chunk_size = 30;
export const loginWithMicrosoftUrl = `${BACKEND_URL}/auth/login/microsoft`;

console.log("BACKEND_URL ", BACKEND_URL);

export const chatDownloadFileUrl = `${BACKEND_URL}/chat/download_file`;

// Create an Axios instance with default configurations
export const axiosInstance = axios.create({
  baseURL: BACKEND_URL,
  withCredentials: true,
});

// Add a response interceptor to handle 401 errors globally
axiosInstance.interceptors.response.use(
  (response) => response, // Return the response if it's successful
  (error) => {
    if (error.response && error.response.status === 401) {
      // Redirect to the login page
      if (
        error.response.data.redirect &&
        error.response.data.redirect !== window.location.pathname
      ) {
        window.location.href = error.response.data.redirect;
      }
    }
    return Promise.reject(error); // Reject the promise if an error occurs
  }
);

export default axiosInstance;

export const getOrganizationUsers = async () => {
  const response = await axiosInstance.get("/users/get_all_organization_users");
  return response.data;
};

export const setUserRoles = async (userId, roles) => {
  const response = await axiosInstance.put(`/users/${userId}/set_roles`, {
    roles,
  });
  return response.data;
};

export const getAllRoles = async () => {
  const response = await axiosInstance.get("/roles/get_all_roles");
  return response.data;
};
