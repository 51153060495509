import React, { useEffect } from "react";
import {
  ReactFlow,
  Controls,
  MiniMap,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  ReactFlowProvider,
  useReactFlow,
} from "@xyflow/react";
import dagre from "dagre";
import { Box, Typography } from "@mui/material";
import "@xyflow/react/dist/style.css";
import StepNode from "./StepNode";

const nodeWidth = 20 * 16;
const nodeHeight = 6 * 16;

const getLayoutedElements = (nodes, edges, direction = "LR") => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const isHorizontal = direction === "LR";
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? "left" : "top";
    node.sourcePosition = isHorizontal ? "right" : "bottom";

    // Shift the dagre node position (anchor=center center) to the top left
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };
  });

  return { nodes, edges };
};

const nodeTypes = { stepNode: StepNode };

const FLD = ({ user, steps, theme, onNodeClick }) => {
  const { fitView } = useReactFlow();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  useEffect(() => {
    try {
      const generatedNodes = [];
      const generatedEdges = [];

      steps.forEach((stepGroup, groupIndex) => {
        stepGroup.forEach((step, stepIndex) => {
          const nodeId = `step-${groupIndex}-${stepIndex}`;
          generatedNodes.push({
            id: nodeId,
            data: {
              title: step.name,
              icon: step.status,
              disabled:
                step.approver.email.toLowerCase() !==
                  user.email.toLowerCase() &&
                (!user.roles ||
                  !user.roles.some((role) =>
                    role.permissions.includes("CHANGE_STATUS")
                  )),
              status: step.status,
              approver: step.approver,
              date: step.date,
              content: (
                <Box display="flex" justifyContent="space-between">
                  <Typography>{step.approver.name}</Typography>
                  <Typography>
                    {new Intl.DateTimeFormat("en-US", {
                      month: "short",
                      day: "numeric",
                    }).format(new Date(step.date))}
                  </Typography>
                </Box>
              ),
              nodeWidth: nodeWidth,
              nodeHeight: nodeHeight,
            },
            type: "stepNode",
            draggable: false,
          });

          // If not the first group, create edges from all nodes in the previous group
          if (groupIndex > 0) {
            const prevGroup = steps[groupIndex - 1];
            prevGroup.forEach((prevStep, prevIndex) => {
              const sourceId = `step-${groupIndex - 1}-${prevIndex}`;
              const targetId = nodeId;
              generatedEdges.push({
                id: `${sourceId}-${targetId}`,
                source: sourceId,
                target: targetId,
                animated: true,
                style: {
                  stroke: theme.palette.success.main,
                  strokeWidth: 2,
                },
              });
            });
          }
        });
      });

      const layoutedElements = getLayoutedElements(
        generatedNodes,
        generatedEdges
      );
      setNodes(layoutedElements.nodes);
      setEdges(layoutedElements.edges);

      window.requestAnimationFrame(() => {
        fitView({ padding: 0.2 });
      });
    } catch (error) {
      console.error("Error parsing data for FlowDiagram:", error);
    }
  }, [steps, theme, fitView, setNodes, setEdges]);

  const onConnect = (params) => setEdges((eds) => addEdge(params, eds));

  return (
    <Box
      sx={{
        width: "100%",
        height: "15rem",
        border: `1px solid ${theme.palette.background.default}`,
        backgroundColor: theme.palette.background.default,
        borderRadius: 2,
        mt: 2,
      }}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        fitView
        attributionPosition="top-right"
        onNodeClick={(event, node) => onNodeClick(node.data)}
        defaultViewport={{ x: 0, y: 0, zoom: 0.5 }}
      >
        <Background variant="dots" gap={12} size={1} />
        <Controls showInteractive={false} style={{ flexDirection: "row" }} />
      </ReactFlow>
    </Box>
  );
};

export default function FlowDiagram({ user, steps, theme, onNodeClick }) {
  return (
    <ReactFlowProvider>
      <FLD user={user} steps={steps} theme={theme} onNodeClick={onNodeClick} />
    </ReactFlowProvider>
  );
}
