import axios from "axios";
import { axiosInstance, BACKEND_URL } from "./general";

// Updated: Implement the real API call for sending email
export const sendEmail = async (formData) => {
  try {
    const response = await axiosInstance.post(`/email/send`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error sending email:", error);
    throw error;
  }
};

export const getAttachmentDownloadUrl = (emailId, attachmentId) => {
  return `${BACKEND_URL}/email/download_attachment/${emailId}/${attachmentId}`;
};

// New function to check for new emails
export const checkForNewMails = async (latestEmailDate) => {
  try {
    const response = await axiosInstance.post(
      "/email/check_for_requests_updates",
      {
        latestEmailDate,
      }
    );

    if (response.data.success) {
      return response.data.newEmails;
    }
  } catch (error) {
    console.error("Error checking for new mails:", error);
    throw error;
  }
};
