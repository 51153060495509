import React, { useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemButton,
  IconButton,
  CircularProgress,
  Typography,
  Modal,
} from "@mui/material";
import { downloadRequestFile, viewRequestFile } from "../../../api/request";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FolderIcon from "@mui/icons-material/Folder";

const DocumentExplorer = ({ files, requestId }) => {
  const [open, setOpen] = useState(false);
  const [currentFileUrl, setCurrentFileUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleDownload = async (filename) => {
    try {
      const downloadUrl = await downloadRequestFile(requestId, filename);
      // Open the download URL in a new window
      window.open(downloadUrl, "_blank");
    } catch (error) {
      alert("Failed to download file.");
    }
  };

  const handleView = async (filename) => {
    setLoading(true);
    setError("");
    try {
      const viewUrl = await viewRequestFile(requestId, filename);
      setCurrentFileUrl(viewUrl);
      setOpen(true);
    } catch (error) {
      setError("Failed to load file for viewing.");
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentFileUrl("");
    setError("");
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "95%",
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  return (
    <Box>
      <List>
        {files.map((file, index) => (
          <ListItem key={index} sx={{}}>
            <ListItemAvatar>
              <Avatar>
                <FolderIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={file.name}
              secondary={file.description || null}
            />
            <IconButton
              edge="end"
              aria-label="view"
              onClick={() => handleView(file.name)}
              sx={{ mr: 1 }}
            >
              <VisibilityIcon />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="download"
              onClick={() => handleDownload(file.name)}
              sx={{ mr: 2 }}
            >
              <DownloadIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>

      {/* Preview Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="preview-modal-title"
      >
        <Box sx={style}>
          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="100%"
            >
              <CircularProgress />
            </Box>
          ) : error ? (
            <Typography color="error">{error}</Typography>
          ) : (
            <iframe
              src={currentFileUrl}
              title="File Preview"
              width="100%"
              height="100%"
              style={{ border: "none" }}
            ></iframe>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default DocumentExplorer;
