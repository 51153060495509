import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  Box,
  Avatar,
  Divider,
  Switch,
  Button,
  Modal,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import RequestsListPage from "./Requests/RequestsListPage";
import MainPage from "./Main/MainPage";
import InboxPage from "./InboxPage";
import RequestPage from "./Requests/RequestPage";
import Settings from "./Settings/Settings"; // Import the Settings component
import { logout } from "../api/auth";
import useDashboard from "../hooks/useDashboard";
import DrawerComponent from "./DrawerComponent";
import routeConfig from "./routeConfig"; // Import route configurations

const Dashboard = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const {
    user,
    requests,
    loading,
    error,
    dummyContacts,
    pauseAccount,
    currentPage,
    totalPages,
    goToNextPage,
    goToPrevPage,
    sortOrder,
    changeSortOrder,
    searchQuery,
    handleSearch,
    statusFilter,
    handleStatusFilter,
  } = useDashboard();
  const location = useLocation();

  const handleLogout = async () => {
    try {
      await logout();
      console.log("Logged out successfully");
      window.location.href = "/";
    } catch (error) {
      console.error("Failed to log out:", error);
    }
  };

  const handlePauseToggle = async (event) => {
    console.log("Account paused:", event.target.checked);
    await pauseAccount(event.target.checked);
  };

  const toggleDrawer = (newOpen) => () => {
    setDrawerOpen(newOpen);
  };

  // Determine if the current route should display the Drawer based on routeConfig
  const showDrawer = Object.keys(routeConfig).some((route) =>
    location.pathname.startsWith(route)
  );

  // Get the current route's title from routeConfig
  const headerTitle =
    routeConfig[
      Object.keys(routeConfig).find((route) =>
        location.pathname.startsWith(route)
      )
    ] || "Dashboard";

  if (error) {
    return (
      <Typography color="text">
        Error loading requests: {error.message}
      </Typography>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      {showDrawer && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              px: 4,
              py: 2,
              width: "100%",
              height: "5rem",
            }}
          >
            <IconButton onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h5"
              sx={{
                mx: 2,
                color: "primary.main",
              }}
            >
              {headerTitle}
            </Typography>
          </Box>

          <DrawerComponent
            drawerOpen={drawerOpen}
            toggleDrawer={toggleDrawer}
            user={user}
            handlePauseToggle={handlePauseToggle}
            handleLogout={handleLogout}
          />
        </>
      )}

      <Box sx={{ flexGrow: 1, width: "100%", overflow: "auto" }}>
        <Routes>
          <Route path="/" element={<Navigate to="all_requests" />} />
          <Route
            path="all_requests"
            element={
              <RequestsListPage
                loading={loading}
                requests={requests}
                user={user}
                searchQuery={searchQuery}
                handleSearch={handleSearch}
                statusFilter={statusFilter}
                handleStatusFilter={handleStatusFilter}
                sortOrder={sortOrder}
                changeSortOrder={changeSortOrder}
              />
            }
          />
          {/* Uncomment if you have an InboxPage */}
          {/* <Route path="inbox" element={<InboxPage />} /> */}
          <Route
            path="request/:requestId"
            element={<RequestPage user={user} contacts={dummyContacts} />}
          />
          <Route path="settings" element={<Settings user={user} />} />{" "}
          {/* New Settings Route */}
          {/* Fallback Route */}
          <Route path="*" element={<Typography>Page Not Found</Typography>} />
        </Routes>
      </Box>

      {/* Pagination Controls */}
      {showDrawer && location.pathname.includes("/all_requests") && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            py: 2,
            borderTop: "1px solid #e0e0e0",
            position: "fixed",
            bottom: 0,
            width: "100%",
            backgroundColor: "#fafafa",
          }}
        >
          <IconButton
            onClick={goToPrevPage}
            disabled={currentPage === 1}
            sx={{ mx: 2 }}
            aria-label="Previous Page"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="body1">
            Page {currentPage} of {totalPages}
          </Typography>
          <IconButton
            onClick={goToNextPage}
            disabled={currentPage === totalPages}
            sx={{ mx: 2 }}
            aria-label="Next Page"
          >
            <ArrowForwardIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default Dashboard;
