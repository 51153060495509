import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Box,
  Typography,
  Grid,
  Button,
  ListItemButton,
} from "@mui/material";
import { format, isValid } from "date-fns";
import ConversationView from "../../../components/Conversation/ConversationView";
import ComposeEmailView from "../../../components/Drafting/ComposeEmailView";

const RelatedConversations = ({
  request,
  sortedRelatedConversations,
  user,
  contacts,
  replySubject,
  setReplySubject,
  replyBody,
  setReplyBody,
  toTags,
  setToTags,
  ccTags,
  setCcTags,
  bccTags,
  setBccTags,
  attachments,
  setAttachments,
  selectedConversation,
  setSelectedConversation,
  currentView,
  setCurrentView,
  resetDrafting,
  draftingEmailId,
  setDraftingEmailId,
}) => {
  const handleOpenConversation = (conversation) => {
    setSelectedConversation(conversation);
    setReplySubject("");
    setReplyBody("");
    setToTags([]);
    setCcTags([]);
    setBccTags([]);
    setAttachments([]);
    setCurrentView("conversation");
  };

  const handleComposeEmail = () => {
    setSelectedConversation(null);
    setCurrentView("compose");
  };

  const handleBack = () => {
    setSelectedConversation(null);
    setCurrentView("list");
  };

  if (currentView === "compose") {
    return (
      <ComposeEmailView
        resetDrafting={resetDrafting}
        replySubject={replySubject}
        setReplySubject={setReplySubject}
        replyBody={replyBody}
        setReplyBody={setReplyBody}
        toTags={toTags}
        setToTags={setToTags}
        ccTags={ccTags}
        setCcTags={setCcTags}
        bccTags={bccTags}
        setBccTags={setBccTags}
        attachments={attachments}
        setAttachments={setAttachments}
        onBack={handleBack}
        contacts={contacts}
        request={request}
      />
    );
  }

  if (currentView === "conversation" && selectedConversation) {
    return (
      <ConversationView
        replySubject={replySubject}
        setReplySubject={setReplySubject}
        replyBody={replyBody}
        setReplyBody={setReplyBody}
        toTags={toTags}
        setToTags={setToTags}
        ccTags={ccTags}
        setCcTags={setCcTags}
        bccTags={bccTags}
        setBccTags={setBccTags}
        attachments={attachments}
        setAttachments={setAttachments}
        request={request}
        conversation={selectedConversation}
        user={user}
        contacts={contacts}
        onBack={handleBack}
        resetDrafting={resetDrafting}
        draftingEmailId={draftingEmailId}
        setDraftingEmailId={setDraftingEmailId}
        // Add any other necessary props
      />
    );
  }

  // Default view: list of conversations
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <List>
        {sortedRelatedConversations.map((conversation) => (
          <ListItem
            // button
            key={conversation.conversation_id}
            onClick={() => handleOpenConversation(conversation)}
            sx={{ p: 0 }}
          >
            <ListItemButton>
              <Grid container spacing={2} alignItems="center">
                <Grid item sx={{ width: "25%" }}>
                  <ListItemText
                    primary={
                      <Typography sx={{ fontWeight: "bold" }} noWrap>
                        {conversation.emails[0].sender.name}
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item sx={{ width: "50%" }}>
                  <ListItemText
                    primary={
                      <Typography noWrap>
                        {conversation.emails[0].subject} -{" "}
                        {conversation.emails[0].preview}
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item sx={{ width: "25%", textAlign: "right" }}>
                  <ListItemText
                    primary={
                      isValid(new Date(conversation.emails[0].received_date))
                        ? format(
                            new Date(conversation.emails[0].received_date),
                            "MMM d"
                          )
                        : "N/A"
                    }
                  />
                </Grid>
              </Grid>
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <Box sx={{ textAlign: "center", mt: 2, pb: 2, width: "100%" }}>
        <Button
          variant="contained"
          onClick={handleComposeEmail}
          sx={{ width: "80%" }}
          color="primary"
        >
          Compose New Email
        </Button>
      </Box>
    </Box>
  );
};

export default RelatedConversations;
