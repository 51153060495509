import { axiosInstance } from "./general";

export const fetchRequestDetails = async (requestId) => {
  try {
    const response = await axiosInstance.post(
      `/requests/details`,
      {
        request_id: requestId,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching request details:", error);
    throw error;
  }
};

// New: Fetch all requests with pagination, sorting, search, and status filter
export const fetchRequests = async (
  page = 1,
  perPage = 10,
  sortOrder = "desc",
  searchQuery = "",
  statusFilter = ""
) => {
  try {
    const params = {
      page,
      per_page: perPage,
      sort_order: sortOrder,
    };

    if (searchQuery) {
      params.search = searchQuery;
    }

    if (statusFilter) {
      params.status = statusFilter;
    }

    const response = await axiosInstance.get(`/requests`, {
      params,
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching requests:", error);
    throw error;
  }
};

// Optionally, fetch a single request
export const fetchRequestById = async (id) => {
  const response = await axiosInstance.get(`/requests/${id}`);
  return response.data;
};

export const updateRequestStatus = async (requestId, stepName, newStatus) => {
  const response = await axiosInstance.post(`/requests/update-status`, {
    request_id: requestId,
    step_name: stepName,
    new_status: newStatus,
  });
  return response.data;
};

export const downloadRequestFile = async (requestId, filename) => {
  try {
    const response = await axiosInstance.get(
      `/requests/${requestId}/download/${filename}`,
      {
        withCredentials: true,
      }
    );
    return response.data.download_url;
  } catch (error) {
    console.error("Error fetching download URL:", error);
    throw error;
  }
};

// New function for viewing files
export const viewRequestFile = async (requestId, filename) => {
  try {
    const response = await axiosInstance.get(
      `/requests/${requestId}/view/${filename}`,
      {
        withCredentials: true,
      }
    );
    return response.data.view_url;
  } catch (error) {
    console.error("Error fetching view URL:", error);
    throw error;
  }
};

export const delegateTask = async (email) => {
  try {
    const response = await axiosInstance.post(
      `/requests/delegate-task`,
      { email },
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    console.error("Error delegating task:", error);
    throw error;
  }
};
