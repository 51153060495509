import React, { useState } from "react";
import { Box, Typography, Tabs, Tab } from "@mui/material";
import Approvals from "./Approvals";
import FormBuilder from "./FormBuilder";
import AdminPanel from "./AdminPanel";

const Settings = ({ user }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const tabs = [
    // { label: "Approvals", component: <Approvals /> },
    {
      label: "Admin Panel",
      component: <AdminPanel />,
      disabled: !(
        user &&
        user.roles &&
        user.roles.some((r) => r.name === "admin")
      ),
    },
    // Add more tabs here as needed
  ];

  return (
    <Box sx={{ px: 4 }}>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        aria-label="Settings Tabs"
        sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}
        textColor="primary"
        indicatorColor="primary"
      >
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab.label} disabled={tab.disabled} />
        ))}
      </Tabs>

      <Box>
        {tabs[activeTab]?.component || (
          <Typography>No settings available</Typography>
        )}
      </Box>
    </Box>
  );
};

export default Settings;
