import { axiosInstance } from "./general";

export const processFiles = async (formData) => {
  try {
    const response = await axiosInstance.post(`/process-files`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        // "Conversation-ID": conversationId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error processing files:", error);
    throw error;
  }
};

export const sendMessageToChatbot = async (formData) => {
  try {
    const response = await axiosInstance.post(
      `/chat/request_agent_chat`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          // "Conversation-ID": conversationId,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error sending message:", error);
    throw error;
  }
};
