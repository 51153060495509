import React, { useState, useEffect, useRef } from "react";
import { Box, Alert, AlertTitle } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MessageList from "./MessageList";
import MessageInput from "./MessageInput";
import FileAttachments from "./File/FileAttachments";
import toolRunningMessages from "./toolRunningMessages.json";
import { sendMessageToChatbot, processFiles } from "../../api/chat";

const Chatbot = ({
  drawerLoadingCount,
  setDrawerLoadingCount,
  conversationOver,
  setConversationOver,
  allFiles,
  setAllFiles,
  messages,
  setMessages,
  activeAgent,
  setActiveAgent,
  handleNewMessages,
  initialOptions,
  extraInfo,
}) => {
  const [input, setInput] = useState("");
  const [chatbotLoading, setChatbotLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const theme = useTheme();
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // const handleProcessFiles = async (messagesWithUserMsg, files) => {
  //   console.log("FILES: ", files);

  //   messagesWithUserMsg.push({
  //     role: "assistant",
  //     content: "Processing files...",
  //     flag: "notification",
  //   });

  //   const formData = new FormData();
  //   formData.append("messages", JSON.stringify(messagesWithUserMsg));

  //   files.forEach((file, index) => {
  //     formData.append(`file${index}`, file);
  //   });

  //   const { newMessages } = await processFiles(formData);
  //   return newMessages;
  // };

  const sendMessage = async (message, files) => {
    let currentUpdateId = Date.now();

    //   setDrawerLoadingCount((count) => count + 1);
    setChatbotLoading(true);

    // Capture the initial number of messages
    const initialMessageCount = messages.length;

    let messagesWithUserMsg = [
      ...messages,
      {
        role: "user",
        content: message,
        files: files.map((file) => ({ name: file.name, type: file.type })),
      },
    ];
    setMessages(messagesWithUserMsg);
    //   if (files.length > 0) {
    //     const newMessages = await handleProcessFiles(messagesWithUserMsg, files);
    //     messagesWithUserMsg = [...messagesWithUserMsg, ...newMessages];
    //   }
    //   setMessages(messagesWithUserMsg);
    const formData = new FormData();
    formData.append("messages", JSON.stringify(messagesWithUserMsg));
    formData.append("updateId", currentUpdateId);
    formData.append("activeAgent", activeAgent);
    formData.append("requestId", extraInfo.request_id);
    formData.append("conversationId", extraInfo.conversation_id || null);

    //   let isConversationOver = conversationOver; // Initialize local variable
    try {
      let continueSending = true;
      let currentMessages = messagesWithUserMsg;
      let latestActiveAgent = activeAgent;

      while (continueSending) {
        console.log("Form Data: ", formData);
        const {
          new_messages: newMessages,
          is_done: isDone,
          data: newData,
        } = await sendMessageToChatbot(formData);

        if (isDone) {
          setConversationOver(true);
          // isConversationOver = true; // Update local variable
        }

        if ("new_active_agent" in newData) {
          latestActiveAgent = newData["new_active_agent"];
          console.log("NEW ACTIVE AGENT: ", latestActiveAgent);
          if (latestActiveAgent) {
            setActiveAgent(latestActiveAgent);
            formData.set("activeAgent", latestActiveAgent);
          }
        }

        currentMessages = [...currentMessages, ...newMessages];
        setMessages(currentMessages);
        formData.set("messages", JSON.stringify(currentMessages));

        if (newMessages[newMessages.length - 1].role !== "tool") {
          continueSending = false;
        }
        console.log("New Messages: ", newMessages);
      }

      setChatbotLoading(false);

      // Isolate only the new messages received during this sendMessage
      const newMessagesFromCurrentSend = currentMessages.slice(
        initialMessageCount + 1
      ); // +1 for the user message

      await handleNewMessages({
        newMessages: newMessagesFromCurrentSend,
        updateId: currentUpdateId,
      });
    } catch (error) {
      console.error("Error sending message:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          role: "assistant",
          content: "Error sending message. Please try again.",
        },
      ]);
      setChatbotLoading(false);
      setSelectedFiles([]);
    } finally {
      // setDrawerLoadingCount((count) => Math.max(count - 1, 0));
    }
  };

  return (
    <Box
      elevation={0}
      sx={{
        height: "90%",
        display: "flex",
        flexDirection: "column",
        px: 4,
        overflow: "hidden",
      }}
    >
      <MessageList
        messages={messages}
        toolRunningMessages={toolRunningMessages}
        theme={theme}
        messagesEndRef={messagesEndRef}
        drawerLoadingCount={drawerLoadingCount}
        chatbotLoading={chatbotLoading}
        sendMessage={sendMessage}
        conversationOver={conversationOver}
        initialOptions={initialOptions}
      />
      {conversationOver ? (
        <Alert severity="success" variant="filled" sx={{ pb: 2, pt: 2 }}>
          <AlertTitle>Conversation Over</AlertTitle>
          Your conversation with the chatbot is over. You can close this window
          now.
        </Alert>
      ) : (
        <>
          <FileAttachments
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            theme={theme}
          />

          <MessageInput
            input={input}
            setInput={setInput}
            sendMessage={sendMessage}
            drawerLoadingCount={drawerLoadingCount}
            chatbotLoading={chatbotLoading}
            theme={theme}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            conversationOver={conversationOver}
            allFiles={allFiles}
            setAllFiles={setAllFiles}
          />
        </>
      )}
    </Box>
  );
};

export default Chatbot;
