import {
  Box,
  IconButton,
  TextField,
  Typography,
  Card,
  CardContent,
  CircularProgress,
  Modal,
  LinearProgress,
  Divider,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useTheme } from "@mui/material/styles";
import EmailTagInput from "./EmailTagInput";
import { useState, useEffect, useRef } from "react";
import { sendEmail } from "../../api/email";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.snow.css";
import "./quill.css";

// {{ Modify modules to include size options and remove other fonts }}
const modules = {
  toolbar: [
    ["bold", "italic", "underline", "link"],
    [
      {
        color: [
          "#18181B",
          "#A9A9A9",
          "#2A9D90",
          "#E76E50",
          "#E8C468",
          "#0097D5",
          "#9B59B6",
        ],
      },
    ],
  ],
};

const formats = [
  "font", // 'font' remains for 'Nunito'
  "size", // Added 'size'
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "color",
  "background",
  "script",
  "list",
  "bullet",
  "direction",
  "align",
  "link",
  "image",
];

const DraftPanel = ({
  contacts,
  email,
  conversation_id,
  request,
  replySubject,
  setReplySubject,
  replyBody,
  setReplyBody,
  toTags,
  setToTags,
  ccTags,
  setCcTags,
  bccTags,
  setBccTags,
  attachments,
  setAttachments,
  bodyInputRef,
  resetDrafting,
}) => {
  const theme = useTheme();
  const [attachmentError, setAttachmentError] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSendSuccess, setIsSendSuccess] = useState(false);
  const subjectInputRef = useRef(null);
  const initialReplySubject = useRef(replySubject);

  // useEffect(() => {
  //     if (initialReplySubject.current === "" && subjectInputRef.current) {
  //         subjectInputRef.current.focus();
  //     } else if (bodyInputRef.current) {
  //         bodyInputRef.current.focus();
  //     }
  // }, []);

  const handleSubjectChange = (e) => setReplySubject(e.target.value || "");
  const handleBodyChange = (value) => setReplyBody(value);

  const handleSend = async () => {
    setIsSending(true);
    setModalOpen(true);
    // setModalMessage("Sending Email...");
    console.log("Sending email...", email);

    const formattedBody = `
        <html>
            <head>
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
            </head>
            <body>
                <div>
                    <div>
                        <div class="">${replyBody}</div>
                    </div>
                    <div style="text-align: center; margin-top: 20px; margin-bottom: 10px;">
                       
                        <a href="https://dashboard.optimizedhq.com/dashboard/request/${
                          request.id
                        }" 
                           style="display: inline-block; padding: 10px 20px; margin-bottom: 5px;
                           background-color: #2A9D90; color: white; text-decoration: none; border-radius: 5px;">
                            Click here to View and Approve/Reject This Request
                        </a>
                        <p>Or visit: https://dashboard.optimizedhq.com/dashboard/request/${
                          request.id
                        }</p> 
                        <br />

                    </div>
                    <div>
                        <div style="display: none; border: 0px; width: 0px; height: 0px; overflow: hidden; visibility: hidden;"></div>
                        <br />
                        <div class="signature">
                            <div style="clear: both">
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
                ${
                  email
                    ? `
                <br />
                <div>
                    <div class="gmail_quote">
                        On ${email.received_date}, ${email.sender.name}
                        <span dir="ltr">&lt;<a href="mailto:${
                          email.sender.address
                        }" target="_blank">${
                        email.sender.address
                      }</a>&gt;</span>
                        wrote:<br />
                        <blockquote class="gmail_quote" style="margin: 0 0 0 0.8ex; border-left: 1px #ccc solid; padding-left: 1ex;">
                            ${email.content.replace(/\n/g, "<br />")}
                        </blockquote>
                    </div>
                </div>
                <br />`
                    : ""
                }
            </body>
        </html>`;

    try {
      const formData = new FormData();
      formData.append("to", JSON.stringify(toTags.map((tag) => tag.id)));
      formData.append("cc", JSON.stringify(ccTags.map((tag) => tag.id)));
      formData.append("bcc", JSON.stringify(bccTags.map((tag) => tag.id)));
      formData.append("subject", replySubject);
      formData.append("body", formattedBody);
      if (conversation_id) formData.append("conversation_id", conversation_id);
      if (email) formData.append("reply_to_message_id", email.id);
      if (request) formData.append("request_id", request.id);
      console.log("requestId", request);

      attachments.forEach((file) => formData.append("attachments", file));

      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }

      const res = await sendEmail(formData);

      console.log("response", res);
      if (res.success) {
        setModalMessage("Email sent successfully!");
        setIsSendSuccess(true);
        setTimeout(() => {
          resetDrafting();
          setModalOpen(false);
        }, 1000);
      } else {
        setModalMessage(`Error sending email: ${res.message}`);
        setIsSendSuccess(false);
      }
    } catch (error) {
      console.error("Error sending email:", error);
      setModalMessage(`Error sending email: ${error.message}`);
      setIsSendSuccess(false);
    } finally {
      setIsSending(false);
    }
  };

  const handleAttachmentChange = (e) => {
    const files = Array.from(e.target.files);
    let totalSize = attachments.reduce((acc, file) => acc + file.size, 0);
    let newAttachments = [...attachments];

    for (let file of files) {
      if (file.size > 10 * 1024 * 1024) {
        setAttachmentError("File size should not exceed 10 MB.");
        return;
      }
      totalSize += file.size;
      if (totalSize > 50 * 1024 * 1024) {
        setAttachmentError("Total attachment size should not exceed 50 MB.");
        return;
      }
      if (newAttachments.length >= 5) {
        setAttachmentError("Cannot attach more than 5 files.");
        return;
      }
      newAttachments.push(file);
    }

    setAttachments(newAttachments);
    setAttachmentError("");
  };

  const handleRemoveAttachment = (index) => {
    const newAttachments = attachments.filter((_, i) => i !== index);
    setAttachments(newAttachments);
  };

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",

        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          // flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
          height: "90%",
        }}
      >
        <EmailTagInput
          tags={toTags}
          setTags={setToTags}
          maxTags={10}
          label="To"
          contacts={contacts}
        />
        <EmailTagInput
          tags={ccTags}
          setTags={setCcTags}
          maxTags={10}
          label="Cc"
          contacts={contacts}
        />
        <EmailTagInput
          tags={bccTags}
          setTags={setBccTags}
          maxTags={10}
          label="Bcc"
          contacts={contacts}
        />
        <TextField
          placeholder="Subject"
          fullWidth
          value={replySubject}
          onChange={handleSubjectChange}
          inputRef={subjectInputRef}
          sx={{ my: 2 }}
        />

        <Box
          sx={{
            minHeight: "10rem",
            height: "100%",
            maxHeight: "100%",
            overflow: "auto",
            // border: "1px solid",
            // borderColor: "divider",
            // borderRadius: 1,
            // mx: 1,
            // p: 1,
          }}
          id="draft-panel-container"
        >
          <ReactQuill
            theme="bubble"
            bounds="#draft-panel-container"
            value={replyBody}
            onChange={handleBodyChange}
            style={{
              height: "auto",
              maxHeight: "15rem",
            }}
            modules={modules}
            formats={formats}
            placeholder="Start typing..."
          />

          {attachments.length > 0 && (
            <Box sx={{ mt: 2, display: "flex", gap: 2, flexWrap: "wrap" }}>
              {attachments.map((file, index) => (
                <Card
                  key={index}
                  variant="outlined"
                  sx={{
                    minWidth: "15rem",
                    position: "relative",
                    maxWidth: "15rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      py: 1,
                      px: 2,
                    }}
                  >
                    <Typography variant="body2" noWrap>
                      {file.name} ({(file.size / (1024 * 1024)).toFixed(2)} MB)
                    </Typography>
                    <Typography
                      variant="body2"
                      color="error"
                      onClick={() => handleRemoveAttachment(index)}
                      sx={{ cursor: "pointer", mr: 1 }}
                    >
                      x
                    </Typography>
                  </Box>
                </Card>
              ))}
            </Box>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          height: "10%",

          mt: 2,
          px: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between", // Ensures space between left and right sections
            width: "100%", // Ensures the inner Box takes full width
            // borderTop: (theme) => `1px solid ${theme.palette.divider}`,
            pt: 1,
          }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            <IconButton component="label">
              <AttachFileIcon />
              <input
                type="file"
                hidden
                multiple
                onChange={handleAttachmentChange}
              />
            </IconButton>
            <IconButton onClick={resetDrafting}>
              <DeleteIcon />
            </IconButton>
          </Box>
          <Box sx={{ display: "flex", gap: 1 }}>
            <IconButton onClick={handleSend} disabled={isSending}>
              {isSending ? <CircularProgress size={24} /> : <SendIcon />}
            </IconButton>
          </Box>
        </Box>
      </Box>
      {/* Error Message */}
      {attachmentError && (
        <Typography color="error" variant="body2" sx={{ mt: 1, px: 1 }}>
          {attachmentError}
        </Typography>
      )}
      {/* Modal */}
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            color={
              isSending ? "primary" : isSendSuccess ? "success.main" : "error"
            }
          >
            {isSending
              ? "Sending Email..."
              : isSendSuccess
              ? "Email sent successfully!"
              : "Error sending email!"}
          </Typography>
          {!isSendSuccess && (
            <Typography id="modal-description" sx={{ mt: 2 }}>
              {modalMessage}
            </Typography>
          )}
          {isSending && <LinearProgress sx={{ width: "100%", mt: 2 }} />}
        </Box>
      </Modal>
    </Box>
  );
};

export default DraftPanel;
