import React, { useEffect } from "react";
import { Box, Grid, Typography, Tabs, Tab } from "@mui/material";
import { motion } from "framer-motion"; // Import Framer Motion
import Overview from "./Tabs/Overview";
import IntakeForm from "./Tabs/IntakeForm";
import RelatedConversations from "./Tabs/RelatedConversations";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Chatbot from "../../components/Chatbot/Chatbot";
import useRequest from "../../hooks/useRequest";
import DocumentExplorer from "./Tabs/DocumentExplorer";
import { useParams, useNavigate } from "react-router-dom";

const pageVariants = {
  initial: {
    opacity: 0,
    x: 10,
    transition: {
      duration: 0.2,
    },
  },
  in: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.2,
    },
  },
  out: {
    opacity: 0,
    x: -10,
    transition: {
      duration: 0.2,
    },
  },
};

const RequestPage = ({ user, contacts }) => {
  const { requestId } = useParams();
  const navigate = useNavigate();

  const {
    messages,
    setMessages,
    drawerLoadingCount,
    setDrawerLoadingCount,
    conversationOver,
    setConversationOver,
    allFiles,
    setAllFiles,
    activeAgent,
    setActiveAgent,
    activeTab,
    setActiveTab,
    relatedConversations,
    sortedRelatedConversations,
    handleNewMessages,
    handleTabChange,
    requestSteps,
    requestForms,
    replySubject,
    setReplySubject,
    replyBody,
    setReplyBody,
    toTags,
    setToTags,
    ccTags,
    setCcTags,
    bccTags,
    setBccTags,
    attachments,
    setAttachments,
    selectedConversation,
    setSelectedConversation,
    currentView,
    setCurrentView,
    resetDrafting,
    request,
    draftingEmailId,
    setDraftingEmailId,
    initialOptions,
    getRequestDetails,
  } = useRequest(requestId);

  useEffect(() => {
    if (!request) {
      // Handle case where request is not found or is loading
      // You can redirect or show an error message
    }
  }, [request]);

  if (!request) {
    return <></>;
  }

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
    >
      <Box sx={{ display: "flex", height: "100vh", overflow: "hidden" }}>
        {/* Left Panel */}
        <Box sx={{ width: "50%", height: "100%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              px: 4,
              py: 2,
              width: "100%",
              overflow: "hidden",
            }}
          >
            <ArrowBack
              sx={{ cursor: "pointer", color: "text.disabled" }}
              onClick={() => {
                if (window.history.length > 1) {
                  navigate(-1);
                } else {
                  navigate("/dashboard");
                }
              }}
            />
            <Typography variant="h5" sx={{ mx: 2 }} noWrap>
              {request.title}
            </Typography>
          </Box>
          <Chatbot
            drawerLoadingCount={drawerLoadingCount}
            setDrawerLoadingCount={setDrawerLoadingCount}
            conversationOver={conversationOver}
            setConversationOver={setConversationOver}
            allFiles={allFiles}
            setAllFiles={setAllFiles}
            messages={messages}
            setMessages={setMessages}
            activeAgent={activeAgent}
            setActiveAgent={setActiveAgent}
            handleNewMessages={handleNewMessages}
            extraInfo={{
              request_id: request.id,
              conversation_id: selectedConversation?.conversationId,
            }}
            initialOptions={initialOptions}
          />
        </Box>

        {/* Right Panel with Tabs */}
        <Box
          sx={{
            width: "50%",
            height: "100%",
            position: "relative",
            borderLeft: "1px solid #e0e0e0",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* Tabs Header */}
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            aria-label="Request Tabs"
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            <Tab label="Overview" />
            <Tab label="Emails" />
            <Tab label={`Documents (${request.files.length})`} />
          </Tabs>

          {/* Tab Content */}
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              height: "calc(100% - 48px)", // Adjust based on Tabs height
            }}
          >
            {activeTab === 0 && (
              <Box
                sx={{
                  px: 3,
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <Box sx={{ mb: 2 }}>
                    <Overview
                      user={user}
                      steps={requestSteps}
                      request={request}
                      getRequestDetails={getRequestDetails}
                    />
                  </Box>

                  {/* IntakeForm Section (Scrollable) */}
                  <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
                    <IntakeForm forms={requestForms} />
                  </Box>
                </Box>
              </Box>
            )}

            {activeTab === 1 && (
              <Box sx={{ height: "100%" }}>
                <RelatedConversations
                  resetDrafting={resetDrafting}
                  user={user}
                  request={request}
                  sortedRelatedConversations={sortedRelatedConversations}
                  contacts={contacts}
                  replySubject={replySubject}
                  setReplySubject={setReplySubject}
                  replyBody={replyBody}
                  setReplyBody={setReplyBody}
                  toTags={toTags}
                  setToTags={setToTags}
                  ccTags={ccTags}
                  setCcTags={setCcTags}
                  bccTags={bccTags}
                  setBccTags={setBccTags}
                  attachments={attachments}
                  setAttachments={setAttachments}
                  selectedConversation={selectedConversation}
                  setSelectedConversation={setSelectedConversation}
                  currentView={currentView}
                  setCurrentView={setCurrentView}
                  draftingEmailId={draftingEmailId}
                  setDraftingEmailId={setDraftingEmailId}
                />
              </Box>
            )}

            {activeTab === 2 && (
              <Box sx={{ height: "100%" }}>
                <DocumentExplorer
                  files={request.files}
                  requestId={request.id}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </motion.div>
  );
};

export default RequestPage;
