import React from "react";
import { Box, Typography, Grid, Pagination } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import FormDisplay from "../../../components/FormDisplay";

const IntakeForm = ({ forms }) => {
  const theme = useTheme();
  const [selectedVersion, setSelectedVersion] = useState(
    forms.length ? forms.length : 1
  );

  const handleVersionChange = (event, value) => {
    setSelectedVersion(value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          mt: 1,
          mb: 1,
          px: 1,
          flexShrink: 0,
        }}
      >
        <Typography variant="subtitle1" sx={{ mr: 2 }}>
          Form Versions:
        </Typography>
        <Pagination
          count={forms.length ? forms.length : 1}
          page={selectedVersion}
          onChange={handleVersionChange}
          color="primary"
          siblingCount={1}
          boundaryCount={1}
        />
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        <FormDisplay formConfig={forms[selectedVersion - 1].form} disabled />
      </Box>
    </Box>
  );
};

export default IntakeForm;
