import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  Button,
  CircularProgress,
  TableContainer,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  getOrganizationUsers,
  getAllRoles,
  setUserRoles,
} from "../../api/general";

// Styled Components

// StyledTableCell for all columns except the first
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
    textAlign: "center", // Center header text
    padding: theme.spacing(1), // Reduce padding for density
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: theme.palette.background.default,
    textAlign: "center", // Center body text
    padding: theme.spacing(1), // Reduce padding for density
  },
}));

// StyledFirstTableCell for the first column
const StyledFirstTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
    textAlign: "left", // Left-align header text
    padding: theme.spacing(1), // Reduce padding for density
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: theme.palette.background.default,
    textAlign: "left", // Left-align body text
    padding: theme.spacing(1), // Reduce padding for density
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // Hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const AdminPanel = () => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]); // All available roles
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    fetchUsers();
    fetchRoles();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await getOrganizationUsers();
      setUsers(
        response.users.map((user) => ({
          ...user,
          roles: user.roles.map((role) => role.name), // Set user.roles to a list of role names
        }))
      );
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch users.");
      setLoading(false);
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await getAllRoles();
      setRoles(response.roles);
    } catch (err) {
      setError("Failed to fetch roles.");
    }
  };

  const handleRoleChange = async (userId, roleName) => {
    setUsers((prevUsers) =>
      prevUsers.map((user) => {
        if (user.id !== userId) return user;
        const hasRole = user.roles.includes(roleName);
        return {
          ...user,
          roles: hasRole
            ? user.roles.filter((role) => role !== roleName)
            : [...user.roles, roleName],
        };
      })
    );
  };

  const handleSave = async () => {
    setSaving(true);
    setError("");
    try {
      for (const user of users) {
        await setUserRoles(user.id, user.roles);
      }
      setSaving(false);
      setSuccess("Roles updated successfully.");
    } catch (err) {
      setError("Failed to update roles.");
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      {error && (
        <Typography color="error" gutterBottom>
          {error}
        </Typography>
      )}
      {success && (
        <Typography color="success.main" gutterBottom>
          {success}
        </Typography>
      )}
      <TableContainer
        component={Paper}
        sx={{
          width: "80%", // Set table width to 80%
          margin: "0 auto", // Center the table horizontally
          mt: 4,
        }}
      >
        <Table
          sx={{ minWidth: 700 }}
          aria-label="customized table"
          size="small" // Make the table dense
        >
          <TableHead>
            <TableRow>
              <StyledFirstTableCell>Name</StyledFirstTableCell>
              <StyledFirstTableCell>Email</StyledFirstTableCell>
              {roles.map((role) => (
                <StyledTableCell key={role.name} align="center">
                  {role.name}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <StyledTableRow key={user.id}>
                <StyledFirstTableCell component="th" scope="row">
                  {user.name}
                </StyledFirstTableCell>
                <StyledFirstTableCell>{user.email}</StyledFirstTableCell>
                {roles.map((role) => (
                  <StyledTableCell key={role.name} align="center">
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <Checkbox
                        checked={user.roles.includes(role.name)}
                        onChange={() => handleRoleChange(user.id, role.name)}
                        color="primary"
                        inputProps={{
                          "aria-label": user.name + " " + role.name,
                        }}
                      />
                    </Box>
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ mt: 4, mb: 4, display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={saving}
        >
          {saving ? <CircularProgress size={24} /> : "Save Changes"}
        </Button>
      </Box>
    </Box>
  );
};

export default AdminPanel;
